<template>
  <div
    class="qr-code p-4 bg-light d-flex flex-column justify-content-center text-center"
  >
    <h6>マニュアル検索専用QRコード</h6>
    <qr-code v-if="slug" :text="slug" :size="100" />
  </div>
</template>

<script>
export default {
  name: 'ManualQRCode',

  props: {
    slug: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>
.qr-code img {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0.5rem;
  margin: 0 auto;
}
</style>
