import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import { initializeApp } from 'firebase/app'
import VueQRCodeComponent from 'vue-qrcode-component-v3'

// Import Bootstrap and BootstrapIcon CSS files
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

// Import Vue-multiselect CSS files
import 'vue-multiselect/dist/vue-multiselect.css'

// Import animation.css
import 'animate.css/animate.min.css'

// import Swiper styles
import 'swiper/css/bundle'

// firebase config
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
}
initializeApp(firebaseConfig)

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueCookies, {
  expireTimes: '1d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'Lax',
})
app.component('qr-code', VueQRCodeComponent)
app.mount('#app')
