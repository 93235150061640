<template>
  <div class="row g-3">
    <div class="col-12 col-lg-3">
      <!-- Slider main container -->
      <div
        class="swiper swiper-thumb"
        :style="{ maxHeight: main_height + 'px' }"
      >
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- slides -->
          <div v-for="step in steps" :key="step.id" class="swiper-slide">
            <div class="border border-light">
              <ImageViewer
                :file="step"
                :show_caption="false"
                :show_lightbox="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-9">
      <!-- Slider main container -->
      <div class="swiper swiper-main">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- slides -->
          <div v-for="step in steps" :key="step.id" class="swiper-slide">
            <div
              v-if="
                isVideo(step) &&
                step.file_path &&
                step?.file_status == 'publish'
              "
            >
              <VideoPlayer ref="videoPlayerElem" :file="step" />
            </div>
            <div v-else>
              <ImageViewer ref="imageViewerElem" :file="step" />
            </div>
            <div
              v-if="step.description"
              class="card card-body rounded-0 mt-2 text-pre-line"
            >
              {{ step.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Thumbs, EffectFade, Mousewheel } from 'swiper'
import { isVideo } from '@/utils/fileTypeUtils.js'
import ImageViewer from '@/components/AppImageViewer.vue'
import VideoPlayer from '@/components/AppVideoPlayer.vue'

export default {
  name: 'ManualStepData',
  components: {
    ImageViewer,
    VideoPlayer,
  },

  props: {
    steps: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      swiperThumb: null,
      swiper: null,
      main_height: 0,
    }
  },

  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize)

    this.swiperThumb = new Swiper('.swiper-thumb', {
      spaceBetween: 5,
      slidesPerView: 3,
      breakpoints: {
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 4,
          direction: 'vertical',
        },
      },
      modules: [Mousewheel],
      mousewheel: {
        invert: false,
      },
    })
    this.swiper = new Swiper('.swiper-main', {
      allowTouchMove: false,
      modules: [Thumbs, EffectFade],
      thumbs: {
        swiper: this.swiperThumb,
      },
      effect: 'fade',
      // https://swiperjs.com/swiper-api#events
      on: {
        slideChange: () => {
          this.stopVideoPlay()
        },
      },
    })
  },
  beforeDestroy() {
    this.disposeVideoPlay()
  },

  methods: {
    isVideo,
    slideTo(index) {
      this.stopVideoPlay()
      if (this.swiper) {
        this.swiper.slideTo(index)
      }
    },
    stopVideoPlay() {
      if (this.$refs.videoPlayerElem) {
        this.$refs.videoPlayerElem.forEach((player) => {
          player.pause()
        })
      }
    },
    disposeVideoPlay() {
      if (this.$refs.videoPlayerElem) {
        this.$refs.videoPlayerElem.forEach((player) => {
          player.dispose()
        })
      }
    },
    resize() {
      this.main_height =
        window.innerHeight -
        window.document.querySelector('header').clientHeight -
        parseFloat(getComputedStyle(document.documentElement).fontSize) // mt-3 の分
    },
  },
}
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-thumb .swiper-slide {
  opacity: 0.4;
  height: auto !important;
}

.swiper-thumb .swiper-slide-thumb-active {
  opacity: 1;
  border-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.swiper-thumb .swiper-slide-thumb-active .border {
  border-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
  --bs-border-width: 5px;
}

.swiper-main .swiper-slide {
  opacity: 0 !important;
}

.swiper-main .swiper-slide-active {
  opacity: 1 !important;
}
</style>
