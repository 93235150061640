import { createRouter, createWebHistory } from 'vue-router'

import AppHome from '@/pages/AppHome.vue'
import AppLogin from '@/pages/AppLogin.vue'
import NotFound from '@/pages/NotFound.vue'
import ManualList from '@/pages/member/ManualList.vue'
import ManualDetail from '@/pages/member/ManualDetail.vue'

import Manage from './manage'
import Mypage from './mypage'
import Public from './public'

// store
import store from '@/store'

const routes = [
  {
    path: '/',
    component: AppHome,
    name: 'AppHome',
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        component: ManualList,
        name: 'ManualList',
      },
      {
        path: '/manuals/:slug([a-f0-9-]+)',
        component: ManualDetail,
        name: 'ManualDetail',
        props: (route) => ({ slug: route.params.slug }),
      },
    ],
  },
  {
    path: '/login',
    component: AppLogin,
    name: 'AppLogin',
  },
  ...Manage.routes,
  ...Mypage.routes,
  ...Public.routes,
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters['auth/is_logged_in']) {
    if (store.getters['auth/is_token']) {
      store.dispatch('auth/renew').then(() => {
        next()
      })
    } else {
      router.replace({
        name: 'AppLogin',
        query: { redirect: to.fullPath },
      })
    }
  } else {
    next()
  }
})

export default router
