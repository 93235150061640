<template>
  <div class="row g-2">
    <div
      v-for="manual in manuals"
      :key="manual.id"
      class="col-12 col-md-6 col-lg-4"
    >
      <div class="card rounded-0 h-100">
        <div class="card-img-top">
          <ImageViewer
            :file="{
              file_path: manual.file_path,
              file_type: manual.file_type,
            }"
            :show_caption="false"
            :show_lightbox="false"
          />
        </div>
        <div class="card-body">
          <h5 class="card-title">
            <a
              href="#"
              v-on:click.prevent="detailLink(manual)"
              class="link-secondary text-decoration-none stretched-link"
              >{{ textWrap(manual.title, 12) }}</a
            >
          </h5>
          <ul class="list-inline small text-muted">
            <li><i class="bi bi-globe me-1"></i>{{ manual.status_name }}</li>
            <li class="list-inline-item">
              <i class="bi bi-person me-1"></i
              ><span v-if="manual.author">{{ manual.author }}</span
              ><span v-else class="text-muted">削除済みユーザー</span>
            </li>
            <li class="list-inline-item">
              <i class="bi bi-arrow-repeat me-1"></i>{{ manual.updated_at }}
            </li>
          </ul>
          <p class="card-text">
            {{ textWrap(manual.summary, 50) }}
          </p>
        </div>
        <div class="card-footer border-top-0 text-muted small">
          <i class="bi bi-tags me-1"></i
          >{{ manual.categories.map((obj) => obj.name).join(', ') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { textWrap } from '@/utils/stringUtils.js'
import ImageViewer from '@/components/AppImageViewer.vue'

export default {
  name: 'ManualDataBox',
  components: {
    ImageViewer,
  },

  emits: ['detailLink'],
  props: {
    manuals: {
      type: Array,
      required: true,
    },
  },

  methods: {
    textWrap,
    detailLink(manual) {
      this.$emit('detailLink', manual)
    },
  },
}
</script>
