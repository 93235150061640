import MypageHome from '@/pages/mypage/Home.vue'
import MypageManualList from '@/pages/mypage/ManualList.vue'
import MypageManualDetail from '@/pages/mypage/ManualDetail.vue'
import MypageManualEdit from '@/pages/mypage/ManualEdit.vue'

export default {
  routes: [
    {
      path: '/mypage',
      component: MypageHome,
      name: 'MypageHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'list',
          component: MypageManualList,
          name: 'MypageManualList',
        },
        {
          path: 'manuals/:id([0-9]+)',
          component: MypageManualDetail,
          name: 'MypageManualDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
        {
          path: 'manuals/:id([0-9]+)/edit',
          component: MypageManualEdit,
          name: 'MypageManualEdit',
          props: (route) => ({ id: Number(route.params.id) }),
        },
      ],
    },
  ],
}
