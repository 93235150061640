<template>
  <CodeReader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="handleQRCodeData"
    @cancel="cancelCodeCamera"
  />
  <div v-else>
    <MemberNavbar />
    <header class="bg-light border-bottom">
      <div class="container-fluid px-4">
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="m-0 py-3 py-sm-4">マニュアル一覧</h3>
        </div>
      </div>
    </header>

    <main>
      <div class="container-fluid px-4 pb-4">
        <div
          class="d-flex align-items-center justify-content-between pt-3 pb-2"
        >
          <div>
            <a
              class="btn btn-light"
              :class="{ 'btn-dark text-light': is_search }"
              data-bs-toggle="offcanvas"
              data-bs-target="#searchBoxOffcanvas"
              aria-controls="searchBoxOffcanvas"
              ><i class="bi bi-search"></i
            ></a>
            <a class="btn btn-light ms-2" @click.prevent="showCodeCamera = true"
              ><i class="bi bi-qr-code"></i
            ></a>
          </div>
          <div class="d-flex justify-content-start gap-2 mb-2">
            <Pagination
              :count="count"
              :prev="prev"
              :next="next"
              :current_page="current_page"
              :total_pages="total_pages"
              :page_range="5"
              :show_count="true"
              @move-page="movePage"
            />
            <a class="btn btn-light" @click.prevent="displayChange">
              <i v-if="is_list" class="bi bi-grid"></i>
              <i v-else class="bi bi-list"></i
            ></a>
          </div>
        </div>
        <div v-if="manuals && manuals.length > 0">
          <ManualDataList
            v-if="is_list"
            @detail-link="detailLink"
            :manuals="manuals"
          />
          <ManualDataBox v-else @detail-link="detailLink" :manuals="manuals" />
        </div>
        <p v-else class="mt-3">該当するデータはありません。</p>
      </div>
    </main>
    <div
      class="offcanvas offcanvas-start"
      data-bs-backdrop="static"
      tabindex="-1"
      id="searchBoxOffcanvas"
      aria-labelledby="searchBoxLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="searchBoxLabel">マニュアル検索</h5>
        <button
          type="button"
          id="searchBoxClose"
          class="btn-close d-none"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="card card-body">
          <form>
            <div class="mb-4">
              <label class="text-muted mb-1" for="searchTitle">タイトル</label>
              <input
                v-model="query.title"
                type="text"
                class="form-control"
                id="searchTitle"
              />
            </div>
            <div class="mb-4">
              <label class="text-muted mb-1" for="author">更新ユーザー</label>
              <input
                v-model="query.author"
                type="text"
                class="form-control"
                id="author"
              />
            </div>
            <div class="mb-4">
              <label class="text-muted mb-1" for="category">カテゴリー</label>
              <select
                v-model="query.category"
                class="form-select"
                id="category"
              >
                <option :value="undefined">---</option>
                <option
                  v-for="category in category_list"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>

            <button
              class="w-100 btn btn-dark mb-2"
              v-on:click.prevent="getQueryResult"
            >
              検索
            </button>
            <button
              class="w-100 btn btn-secondary"
              v-on:click.prevent="resetQuery"
            >
              クリア
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { trimToWhiteSpace } from '@/utils/stringUtils.js'
import { ManualDataBox, ManualDataList } from '@/components/manual'
import { MemberNavbar } from '@/components/navbar'
import Pagination from '@/components/AppPagination.vue'
import CodeReader from '@/components/AppCodeReader.vue'

export default {
  name: 'MemberManual',
  components: {
    MemberNavbar,
    ManualDataBox,
    ManualDataList,
    Pagination,
    CodeReader,
  },

  data() {
    return {
      query: {},
      showCodeCamera: false,
    }
  },
  computed: {
    ...mapGetters({
      manuals: 'member_manual/manuals',
      count: 'member_manual/count',
      prev: 'member_manual/prev',
      next: 'member_manual/next',
      current_page: 'member_manual/current_page',
      total_pages: 'member_manual/total_pages',
      query_params: 'member_manual/query_params',
      is_list: 'member_manual/is_list',
      category_list: 'member_manual_category/list',
    }),
    is_search: function () {
      let search_query = Object.assign({}, this.query)
      delete search_query['page']
      return !!Object.keys(search_query).length
    },
  },

  async created() {
    await this.getData()
    await this.getCategoryList()
    this.query = this.query_params
  },

  methods: {
    trimToWhiteSpace,
    ...mapActions({
      setQuery: 'member_manual/setQuery',
      getData: 'member_manual/fetchAll',
      displayChange: 'member_manual/displayChange',
      getCategoryList: 'member_manual_category/fetchList',
    }),
    movePage(page) {
      this.setQuery(Object.assign(this.query, { page: page })).then(() => {
        this.getData()
      })
    },
    getQueryResult() {
      this.setQuery(this.query).then(() => {
        this.getData()
        document.querySelector('#searchBoxClose').click()
      })
    },
    resetQuery() {
      this.query = {}
      this.getQueryResult()
    },
    detailLink(manual) {
      this.$router.push({
        name: 'ManualDetail',
        params: { slug: manual.slug },
      })
    },
    cancelCodeCamera() {
      this.showCodeCamera = false
    },
    handleQRCodeData(result) {
      if (!result) return
      this.$router.push({
        name: 'ManualDetail',
        params: { slug: result },
      })
      this.showCodeCamera = false
    },
  },
}
</script>
