<template>
  <div class="img-draggable-wrap">
    <div
      class="img-draggable"
      @dragenter.prevent="dragEnter"
      @dragleave.prevent="dragLeave"
      @dragover.prevent
      @drop.prevent="dropFile($event)"
      :class="{ 'img-draggable-enter': isEnter }"
    >
      <div
        v-if="
          isVideo(file) && file?.file_path && file?.file_status == 'publish'
        "
      >
        <VideoPlayer ref="videoPlayerElem" :file="file" />
      </div>
      <div v-else-if="file?.file_path">
        <ImageViewer
          ref="imageViewerElem"
          :file="file"
          :show_lightbox="false"
        />
      </div>
      <div v-else class="ratio ratio-16x9">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <i class="bi bi-image fs-1"></i>
          <span class="text-center"
            >対応ファイル種別：<br class="d-md-none" />{{ this.accept }}</span
          >
        </div>
      </div>
    </div>
    <FileSelect
      ref="fileSelect"
      @selected-func="selectedFile"
      :accept="accept"
      :max_file_size="max_file_size"
    />
    <slot name="select-button" :file-select="selectFile"></slot>
  </div>
</template>

<script>
import { isVideo, isImage } from '@/utils/fileTypeUtils.js'
import ImageViewer from '@/components/AppImageViewer.vue'
import VideoPlayer from '@/components/AppVideoPlayer.vue'
import FileSelect from '@/components/AppFileSelect.vue'

export default {
  name: 'AppImageDraggable',
  components: {
    ImageViewer,
    VideoPlayer,
    FileSelect,
  },

  emits: ['selectedFunc', 'deletedFunc'],
  props: {
    accept: {
      type: String,
      required: false,
      default: 'image/jpeg,image/png',
    },
    max_file_size: {
      type: Number,
      required: false,
      default: 20971520,
    },
    file: {
      type: [Object, null],
      required: true,
    },
  },

  data() {
    return {
      isEnter: false,
    }
  },

  methods: {
    isVideo,
    isImage,
    dragEnter() {
      this.isEnter = true
    },
    dragLeave() {
      this.isEnter = false
    },
    dropFile(event) {
      this.$refs.fileSelect.onFileChange(event)
      this.isEnter = false
    },
    deleteFile() {
      this.$emit('deletedFunc')
    },
    selectFile() {
      this.$refs.fileSelect.fileEvent()
    },
    selectedFile(dataURL, fileType) {
      this.$emit('selectedFunc', dataURL, fileType)
    },
  },
}
</script>

<style scoped>
.img-draggable {
  color: #aaa;
  width: 100%;
  border: 1px dashed #eee;
}

.img-draggable-enter {
  background-color: #eee;
}
</style>
