<template>
  <div
    class="modal fade text-body"
    id="changePasswordModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modalTitle">パスワードを変更</slot>
          </h5>
        </div>
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label for="new_password" class="form-label"
                >パスワード<span
                  class="ms-2 small"
                  :class="{ 'text-danger': v$.user.new_password.$error }"
                  >*必須項目</span
                ></label
              >
              <input
                v-model="user.new_password"
                type="password"
                class="form-control"
                :class="{ 'is-invalid': v$.user.new_password.$error }"
                id="new_password"
              />
              <span
                v-if="v$.user.new_password.$error"
                class="text-danger small"
                >{{ v$.user.new_password.$errors[0].$message }}</span
              >
            </div>
            <div class="col-12">
              <label for="re_new_password" class="form-label"
                >パスワード（再入力）</label
              >
              <input
                v-model="user.re_new_password"
                type="password"
                class="form-control"
                :class="{ 'is-invalid': v$.user.re_new_password.$error }"
                id="re_new_password"
                placeholder="確認のためもう一度入力してください"
              />
              <span
                v-if="v$.user.re_new_password.$error"
                class="text-danger small"
                >{{ v$.user.re_new_password.$errors[0].$message }}</span
              >
            </div>
            <div class="col-12">
              <label for="current_password" class="form-label"
                >現在のパスワード<span
                  class="ms-2 small"
                  :class="{ 'text-danger': v$.user.current_password.$error }"
                  >*必須項目</span
                ></label
              >
              <input
                v-model="user.current_password"
                type="password"
                class="form-control"
                :class="{ 'is-invalid': v$.user.current_password.$error }"
                id="current_password"
              />
              <span
                v-if="v$.user.current_password.$error"
                class="text-danger small"
                >{{ v$.user.current_password.$errors[0].$message }}</span
              >
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary"
            type="button"
            @click.prevent="formCancel"
          >
            キャンセル
          </button>
          <button
            class="btn btn-dark"
            type="button"
            @click.prevent="formSubmit"
          >
            保存
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, minLength, sameAs } from '@vuelidate/validators'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  name: 'ProfileChangePassword',
  components: {},

  emits: ['hiddenModal', 'afterSubmit'],

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      modal: null,
      user: {},
      vuelidateExternalResults: {
        user: [],
      },
    }
  },
  computed: {},
  validations() {
    return {
      user: {
        new_password: {
          required: helpers.withMessage('必須項目です', required),
          minLength: helpers.withMessage(
            ({ $params }) => `${$params.min}文字以上で入力してください`,
            minLength(8)
          ),
        },
        re_new_password: {
          required: helpers.withMessage('必須項目です', required),
          sameAs: helpers.withMessage(
            'パスワードが一致しません',
            sameAs(this.user.new_password)
          ),
        },
        current_password: {
          required: helpers.withMessage('必須項目です', required),
        },
      },
    }
  },

  mounted() {
    this.modal = new Modal(document.getElementById('changePasswordModal'))
    document
      .getElementById('changePasswordModal')
      .addEventListener('hidden.bs.modal', () => {
        this.user = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    ...mapActions({
      setPassword: 'auth/setPassword',
    }),
    showModal() {
      this.modal.show()
    },
    async formSubmit() {
      this.v$.$clearExternalResults()
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      try {
        await this.setPassword(this.user)
        await this.$emit('afterSubmit')
        this.v$.$reset()
        this.modal.hide()
      } catch (e) {
        if (e.response.status == 400) {
          // サーバー側の検証エラーを表示
          Object.assign(this.vuelidateExternalResults, {
            user: e.response.data,
          })
        }
      }
    },
    formCancel() {
      this.v$.$reset()
      this.modal.hide()
    },
  },
}
</script>
