<template>
  <div class="animate__animated animate__fadeIn">
    <div class="container-fluid bg-dark">
      <div class="row min-vh-100">
        <div class="col-md p-3 d-flex align-items-center">
          <VideoPlayer
            ref="videoPlayer"
            :file="{
              file_path: file_path,
              file_type: file_type,
            }"
            :show_caption="false"
          />
        </div>
        <div
          class="col-md-auto bg-dark border-start border-secondary d-flex align-items-center"
        >
          <nav class="nav flex-column">
            <div v-if="captureEditor" class="nav-link py-2 px-0">
              <button
                @click="saveImage"
                type="button"
                class="btn text-light fs-2"
              >
                <i class="bi bi-save"></i>
              </button>
            </div>
            <div class="nav-link py-2 px-0">
              <button
                @click="closeEditor"
                type="button"
                class="btn text-light fs-2"
              >
                <i class="bi bi-x-circle"></i>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from '@/components/AppVideoPlayer.vue'

export default {
  name: 'VideoEditor',
  components: {
    VideoPlayer,
  },

  props: {
    captureEditor: {
      type: [Function, Boolean],
      default: false,
      required: false,
    },
    hiddenEditor: {
      type: [Function, Boolean],
      default: false,
      required: false,
    },
    file_path: {
      type: String,
      required: true,
    },
    file_type: {
      type: String,
      required: true,
    },
  },

  methods: {
    saveImage() {
      this.captureEditor(this.file_path, this.file_type)
    },
    closeEditor() {
      this.hiddenEditor()
    },
  },
}
</script>
