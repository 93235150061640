<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark">
    <div class="container-fluid px-4">
      <router-link :to="{ name: 'ManualList' }" class="navbar-brand">
        <img
          :alt="title"
          src="@/assets/logo.svg"
          width="40"
          height="40"
          class="d-inline-block align-text-middle me-2"
        />{{ title }}
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto">
          <li class="nav-item me-2">
            <router-link
              :to="{ name: 'ManualList' }"
              class="nav-link"
              exact
              active-class="active"
              >ホーム
            </router-link>
          </li>
          <li v-for="item in navigation" :key="item.name" class="nav-item me-2">
            <router-link
              :to="item.to"
              class="nav-link"
              exact
              active-class="active"
              >{{ item.name }}
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <span class="nav-link"
              ><Profile ref="Profile">
                <button
                  type="button"
                  class="btn btn-outline-secondary w-100 mb-1"
                  @click="confirmLogout"
                >
                  ログアウト
                </button>
              </Profile></span
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <Confirm ref="confirm" @confirm-true="confirmTrue" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Confirm from '@/components/AppConfirm.vue'
import Profile from '@/components/Profile.vue'

export default {
  name: 'BaseNavbar',
  components: {
    Confirm,
    Profile,
  },

  props: {
    page_navigation: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      navigation: [],
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData',
      hasPermission: 'auth/hasPermission',
    }),
    username: function () {
      return this.userData('username')
    },
  },

  created() {
    if (this.page_navigation && this.page_navigation.length > 0) {
      this.navigation = this.page_navigation
    } else {
      if (this.hasPermission('manage')) {
        this.navigation = [
          {
            name: '管理ページ',
            to: {
              name: 'ManageManualList',
            },
          },
        ]
      }
      if (this.hasPermission('operate')) {
        this.navigation = [
          {
            name: 'マイページ',
            to: {
              name: 'MypageManualList',
            },
          },
        ]
      }
    }
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    confirmLogout() {
      this.$refs.confirm.showConfirm('', 'ログアウトしますか？')
      this.$refs.Profile.closePopover()
    },
    async confirmTrue() {
      await this.logout()
      this.$router.push({
        name: 'AppLogin',
      })
    },
  },
}
</script>

<style>
.nav-link .bi::before {
  font-size: 1.5rem;
  line-height: 1rem;
}
</style>
