<template>
  <div v-if="user && Object.keys(user).length > 0">
    <i class="bi bi-person" id="profilePopover"></i>

    <div class="d-none">
      <div id="profileContent">
        <div class="card-body">
          <h5 class="card-title mb-1">{{ user.display_name }}</h5>
          <h6 class="card-subtitle mb-2 text-muted">{{ user.username }}</h6>
          <p class="card-text">{{ user.groups.join(', ') }}</p>
          <button
            type="button"
            class="btn btn-secondary w-100 mb-1"
            @click="showChangePassword"
          >
            パスワード変更
          </button>
          <button
            type="button"
            class="btn btn-secondary w-100 mb-1"
            @click="showChangeName"
          >
            氏名変更
          </button>
          <slot></slot>
        </div>
      </div>
    </div>

    <ProfileChangePassword ref="profileChangePassword" />
    <ProfileChangeName ref="profileChangeName" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Popover } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import ProfileChangePassword from '@/components/ProfileChangePassword.vue'
import ProfileChangeName from '@/components/ProfileChangeName.vue'

export default {
  name: 'Profile',
  components: {
    ProfileChangePassword,
    ProfileChangeName,
  },

  data() {
    return {
      popover: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  mounted() {
    this.popover = new Popover(document.getElementById('profilePopover'), {
      html: true,
      // trigger: 'focus',// ボタンタップで非表示にならなくなる
      placement: 'bottom',
      content: document.getElementById('profileContent'),
    })
  },
  beforeUnmount() {
    this.popover.hide()
  },

  methods: {
    closePopover() {
      this.popover.hide()
    },
    showChangePassword() {
      this.$refs.profileChangePassword.showModal()
      this.closePopover()
    },
    showChangeName() {
      this.$refs.profileChangeName.showModal()
      this.closePopover()
    },
  },
}
</script>
