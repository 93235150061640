import api from '@/api'
import { fileUpload } from '@/utils/firebaseStorage.js'
import { isDataURL } from '@/utils/fileTypeUtils.js'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: [],
  detail: {},
  query_params: {},
  is_list: false,
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  manuals: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
  detail: (state) => state.detail,
  query_params: (state) => state.query_params,
  is_list: (state) => state.is_list,
  category_names: (state) => {
    if (state.detail.categories) {
      return state.detail.categories.map((obj) => obj['name']).join(', ')
    }
  },
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = {}),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setQueryParams: (state, query_params) => (state.query_params = query_params),
  clearQueryParams: (state) => (state.query_params = {}),
  setIsList: (state, is_list) => (state.is_list = is_list),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearDetail')
      commit('clearQueryParams')
      resolve()
    })
  },
  /**
   * 一覧データを取得する
   *
   * @return {void}
   */
  async fetchAll({ commit, getters }) {
    const response = await api.get('operate/manuals/', {
      params: getters.query_params,
    })
    commit('setData', response.data)
  },
  /**
   * 指定のデータを取得する
   *
   * @param {number}
   * @return {void}
   */
  async fetchData({ commit }, id) {
    const response = await api.get('operate/manuals/' + id + '/')
    commit('setDetail', response.data)
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {void}
   */
  async postData({ commit }, form_data) {
    if (form_data['file_path'] && form_data['file_type']) {
      try {
        const snapshot = await fileUpload(
          form_data['file_path'],
          form_data['file_type']
        )
        console.log('アップロードが完了しました')
        form_data['file_path'] = snapshot.metadata.fullPath
        form_data['file_type'] = snapshot.metadata.contentType
      } catch (error) {
        console.log('アップロードに失敗しました')
      }
    }

    try {
      const response = await api.post('operate/manuals/', form_data)
      commit('setDetail', response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {void}
   */
  async patchData({ commit }, form_data) {
    if (form_data['file_path'] && form_data['file_type']) {
      try {
        const snapshot = await fileUpload(
          form_data['file_path'],
          form_data['file_type']
        )
        console.log('アップロードが完了しました')
        form_data['file_path'] = snapshot.metadata.fullPath
        form_data['file_type'] = snapshot.metadata.contentType
      } catch (error) {
        console.log('アップロードに失敗しました')
      }
    }

    try {
      const response = await api.patch(
        'operate/manuals/' + form_data.id + '/',
        form_data
      )
      commit('setDetail', response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを作成または更新する
   *
   * @param {object}
   * @return {void}
   */
  async postOrPatch({ dispatch }, form_data) {
    // 必要なデータのみにする
    const required_keys = ['id', 'title', 'summary', 'categories']
    // file_path が空もしくは dataURL の場合は送信データに含める
    if (form_data['file_path'] == '' || isDataURL(form_data['file_path'])) {
      required_keys.push('file_path')
      required_keys.push('file_type')
    }
    const submit_data = Object.fromEntries(
      Object.entries(form_data).filter(([key, value]) =>
        required_keys.includes(key)
      )
    )

    try {
      if ('id' in submit_data && submit_data['id']) {
        await dispatch('patchData', submit_data)
      } else {
        await dispatch('postData', submit_data)
      }
    } catch (error) {
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ dispatch }, id) {
    await api.delete('operate/manuals/' + id + '/')
    dispatch('clearAll')
  },
  /**
   * 検索クエリを保存する
   *
   * @param {object}
   * @return {promise}
   */
  setQuery({ commit }, query_params) {
    return new Promise((resolve) => {
      commit('setQueryParams', query_params)
      resolve()
    })
  },
  /**
   * 表示切り替え
   *
   * @return {void}
   */
  displayChange({ state, commit }) {
    commit('setIsList', !state.is_list)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
