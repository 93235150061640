<template>
  <div class="ratio ratio-16x9">
    <video
      v-if="file"
      ref="videoPlayer"
      class="video-js video-player vjs-big-play-centered"
      crossorigin="anonymous"
    >
      <source :src="file.file_path" :type="file.file_type" />
    </video>
  </div>
  <figcaption
    v-if="show_caption && file.caption"
    class="figure-caption mt-1 text-center"
  >
    {{ file.caption }}
  </figcaption>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

export default {
  name: 'AppVideoPlayer',
  components: {},

  emits: ['playEvent', 'endedEvent'],
  props: {
    file: {
      type: Object,
      required: true,
    },
    show_caption: {
      type: Boolean,
      require: false,
      default: true,
    },
  },

  data() {
    return {
      player: null,
    }
  },

  mounted() {
    this.player = videojs(this.$refs.videoPlayer, {
      autoplay: false,
      controls: true,
      playsinline: true,
      preload: true,
      children: [
        // custom Video.js children component
        // https://videojs.com/guides/components/#default-component-tree
        'mediaLoader',
        'posterImage',
        'bigPlayButton',
        'loadingSpinner',
        'controlBar',
        'textTrackDisplay',
      ],
      controlBar: {
        fullscreenToggle: true,
        pictureInPictureToggle: false,
      },
    })
    this.player.on('play', () => {
      this.$emit('playEvent')
    })
    this.player.on('ended', () => {
      this.$emit('endedEvent')
    })
  },
  unmounted() {
    this.dispose()
  },
  beforeDestroy() {
    this.dispose()
  },

  methods: {
    play() {
      if (this.player && this.player.paused()) {
        this.player.play()
      }
    },
    pause() {
      if (this.player && !this.player.paused()) {
        this.player.pause()
      }
    },
    resetPlayer() {
      if (this.player && !this.player.paused()) {
        this.player.pause()
        this.player.currentTime(0)
      }
    },
    dispose() {
      if (this.player) {
        this.player.dispose()
      }
    },
  },
}
</script>
