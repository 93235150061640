<template>
  <main class="form-login d-flex align-items-center bg-light">
    <div class="card-wrapper">
      <div class="mb-3 text-center">
        <h3 class="m-0 p-0">{{ app_name }}</h3>
      </div>
      <div class="card shadow-sm">
        <div class="card-body">
          <form @submit.prevent="formSubmit" class="p-3">
            <div class="mb-3">
              <label class="form-label" for="organization"
                >組織アカウント</label
              >
              <input
                v-model="form_data.organization"
                type="text"
                class="form-control"
                id="organization"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="username">ユーザー名</label>
              <input
                v-model="form_data.username"
                type="text"
                class="form-control"
                id="username"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="password">パスワード</label>
              <input
                v-model="form_data.password"
                type="password"
                class="form-control"
                id="password"
              />
            </div>
            <button type="submit" class="w-100 btn btn-primary">
              ログイン
            </button>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AppLogin',
  components: {},

  data() {
    return {
      form_data: {},
    }
  },
  computed: {
    app_name() {
      return process.env.VUE_APP_TITLE
    },
  },

  created() {
    this.boot()
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      boot: 'auth/boot',
    }),
    async formSubmit() {
      try {
        await this.login(this.form_data)
        const redirect = this.$route.query.redirect || '/'
        this.$router.replace(redirect)
      } catch (e) {
        if (e.response.status == 400) {
          this.$store.dispatch('alert/setErrorMessage', {
            message: '入力されたログイン情報が間違っています。',
          })
        }
      }
    },
  },
}
</script>

<style scoped>
.form-login {
  height: 100vh;
}

.form-login .card-wrapper {
  margin: 0 auto;
  max-width: 90%;
  width: 360px;
}
</style>
