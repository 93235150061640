import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: [],
  query_params: {},
  group_list: [],
  member_list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  users: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
  query_params: (state) => state.query_params,
  group_list: (state) => state.group_list,
  member_list: (state) => state.member_list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = {}),
  setQueryParams: (state, query_params) => (state.query_params = query_params),
  clearQueryParams: (state) => (state.query_params = {}),
  setGroupList: (state, group_list) => (state.group_list = group_list),
  clearGroupList: (state) => (state.group_list = []),
  setMemberList: (state, member_list) => (state.member_list = member_list),
  clearMemberList: (state) => (state.member_list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearQueryParams')
      commit('clearGroupList')
      commit('clearMemberList')
      resolve()
    })
  },
  /**
   * 一覧データを取得する
   *
   * @return {void}
   */
  async fetchAll({ commit, getters }) {
    const response = await api.get('manage/users/', {
      params: getters.query_params,
    })
    commit('setData', response.data)
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {void}
   */
  async postData({}, form_data) {
    try {
      await api.post('manage/users/', form_data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {void}
   */
  async patchData({}, form_data) {
    try {
      await await api.patch('manage/users/' + form_data.id + '/', form_data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを作成または更新する
   *
   * @param {object}
   * @return {void}
   */
  async postOrPatch({ dispatch }, form_data) {
    // 必要なデータのみにする
    const required_keys = [
      'id',
      'username',
      'password',
      'first_name',
      'last_name',
      'group_id',
      'is_active',
    ]
    const submit_data = Object.fromEntries(
      Object.entries(form_data).filter(([key, value]) =>
        required_keys.includes(key)
      )
    )

    try {
      if ('id' in submit_data && submit_data['id']) {
        await dispatch('patchData', submit_data)
      } else {
        await dispatch('postData', submit_data)
      }
      await dispatch('clearAll')
    } catch (error) {
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ dispatch }, id) {
    await api.delete('manage/users/' + id + '/')
    dispatch('clearAll')
  },
  /**
   * 検索クエリを保存する
   *
   * @param {object}
   * @return {promise}
   */
  setQuery({ commit }, query_params) {
    return new Promise((resolve) => {
      commit('setQueryParams', query_params)
      resolve()
    })
  },
  /**
   * 権限グループリストを取得する
   *
   * @return {void}
   */
  async fetchGroupList({ state, commit }) {
    if (state.group_list.length > 0) {
      return
    }

    const response = await api.get('manage/groups/list/')
    commit('setGroupList', response.data)
  },
  /**
   * メンバーリストを取得する
   *
   * @return {void}
   */
  async fetchMemberList({ state, commit }) {
    if (state.member_list.length > 0) {
      return
    }

    const params = {
      group_slug: 'member',
    }
    const response = await api.get('manage/users/list/', {
      params: params,
    })
    commit('setMemberList', response.data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
