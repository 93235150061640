import { createStore } from 'vuex'
import auth from './modules/auth'
import alert from './modules/alert'
import loading from './modules/loading'
import member_manual from './member/manual'
import member_manual_category from './member/manual_category'
import mypage_manual from './mypage/manual'
import mypage_manual_category from './mypage/manual_category'
import mypage_manual_step from './mypage/manual_step'
import manage_manual from './manage/manual'
import manage_manual_category from './manage/manual_category'
import manage_manual_step from './manage/manual_step'
import manage_user from './manage/user'
import public_manual from './public/manual'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('auth/clearAll', null, { root: true })
      dispatch('alert/clearAll', null, { root: true })
      dispatch('loading/clearAll', null, { root: true })
      dispatch('member_manual/clearAll', null, { root: true })
      dispatch('member_manual_category/clearAll', null, { root: true })
      dispatch('mypage_manual/clearAll', null, { root: true })
      dispatch('mypage_manual_category/clearAll', null, { root: true })
      dispatch('mypage_manual_step/clearAll', null, { root: true })
      dispatch('manage_manual/clearAll', null, { root: true })
      dispatch('manage_manual_category/clearAll', null, { root: true })
      dispatch('manage_manual_step/clearAll', null, { root: true })
      dispatch('manage_user/clearAll', null, { root: true })
      dispatch('public_manual/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('member_manual/clearAll', null, { root: true })
      dispatch('member_manual_category/clearAll', null, { root: true })
      dispatch('mypage_manual/clearAll', null, { root: true })
      dispatch('mypage_manual_category/clearAll', null, { root: true })
      dispatch('mypage_manual_step/clearAll', null, { root: true })
      dispatch('manage_manual/clearAll', null, { root: true })
      dispatch('manage_manual_category/clearAll', null, { root: true })
      dispatch('manage_manual_step/clearAll', null, { root: true })
      dispatch('manage_user/clearAll', null, { root: true })
      dispatch('public_manual/clearAll', null, { root: true })
    },
  },
  modules: {
    auth,
    alert,
    loading,
    member_manual,
    member_manual_category,
    mypage_manual,
    mypage_manual_category,
    mypage_manual_step,
    manage_manual,
    manage_manual_category,
    manage_manual_step,
    manage_user,
    public_manual,
  },
})

export default store
