<template>
  <div class="row g-3">
    <div class="col-lg-3">
      <div
        class="row flex-row flex-lg-column flex-nowrap overflow-auto"
        :style="{ maxHeight: mainHeight + 'px' }"
      >
        <div
          v-for="(step, index) in steps"
          :key="index"
          class="mb-lg-1 col-6 col-md-4 col-lg-12"
        >
          <div class="position-relative">
            <div
              class="border"
              :class="[
                index == this.currentStepIndex
                  ? 'border-warning border-5'
                  : 'border-light',
              ]"
              @click.prevent="handleCurrentChange(index)"
            >
              <ImageViewer
                :file="step"
                :show_caption="false"
                :show_lightbox="false"
              />
            </div>
            <div class="position-absolute top-0 start-0 w-100">
              <slot name="thumbnail-operation-icons" :index="index"></slot>
            </div>
          </div>
        </div>
        <!-- Step 追加ボタン -->
        <div class="mb-lg-1 col-6 col-md-4 col-lg-12">
          <slot name="add-button"></slot>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-9">
      <div class="position-relative">
        <div v-if="currentStep && Object.keys(currentStep).length > 0">
          <ImageDraggable
            ref="imageDraggable"
            accept="image/jpeg,image/png,video/mp4"
            :max_file_size="52428800"
            :file="currentStep"
            @selected-func="setSelectedFile"
          />
          <textarea
            v-model="currentStep.description"
            class="form-control rounded-0 my-2"
            id="description"
            rows="3"
          >
          </textarea>
          <slot name="footer"></slot>
          <div class="position-absolute top-0 end-0 m-2">
            <slot
              name="current-operation-icons"
              :select-file="selectFile"
            ></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isVideo, isImage } from '@/utils/fileTypeUtils.js'
import ImageViewer from '@/components/AppImageViewer.vue'
import ImageDraggable from '@/components/AppImageDraggable.vue'

export default {
  name: 'ManualStepDataEdit',
  components: {
    ImageViewer,
    ImageDraggable,
  },

  emits: ['currentChange', 'imageCapture', 'videoCapture'],
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStepIndex: {
      type: [Number, null],
      required: true,
    },
  },

  data() {
    return {
      isEnter: false,
      mainHeight: 0,
      currentStep: {},
    }
  },

  watch: {
    currentStepIndex: {
      handler() {
        this.setCurrentStep()
      },
      immediate: true,
    },
  },

  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize)
  },

  methods: {
    isVideo,
    isImage,
    setCurrentStep() {
      this.currentStep = this.steps[this.currentStepIndex]
    },
    resize() {
      this.mainHeight =
        window.innerHeight -
        window.document.querySelector('header').clientHeight -
        parseFloat(getComputedStyle(document.documentElement).fontSize) // mt-3 の分

      if (window.document.querySelector('#header-alert')) {
        this.mainHeight =
          this.mainHeight -
          window.document.querySelector('#header-alert').clientHeight
      }
    },
    selectFile() {
      this.$refs.imageDraggable.selectFile()
    },
    setSelectedFile(dataURL, fileType) {
      // 画像を選択した場合は画像加工画面を表示する
      if (isImage({ file_type: fileType })) {
        this.$emit('imageCapture', dataURL, fileType)
        return
      }
      // 動画の場合は確認画面を表示する
      if (isVideo({ file_type: fileType })) {
        this.$emit('videoCapture', dataURL, fileType)
        return
      }
    },
    handleCurrentChange(index) {
      this.$emit('currentChange', index)
    },
  },
}
</script>
