import ManageHome from '@/pages/manage/Home.vue'
import ManageManualList from '@/pages/manage/ManualList.vue'
import ManageManualDetail from '@/pages/manage/ManualDetail.vue'
import ManageManualEdit from '@/pages/manage/ManualEdit.vue'
import ManageCategoryList from '@/pages/manage/CategoryList.vue'
import ManageUserList from '@/pages/manage/UserList.vue'

export default {
  routes: [
    {
      path: '/manage',
      component: ManageHome,
      name: 'ManageHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'list',
          component: ManageManualList,
          name: 'ManageManualList',
        },
        {
          path: 'manuals/:id([0-9]+)',
          component: ManageManualDetail,
          name: 'ManageManualDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
        {
          path: 'manuals/:id([0-9]+)/edit',
          component: ManageManualEdit,
          name: 'ManageManualEdit',
          props: (route) => ({ id: Number(route.params.id) }),
        },
        {
          path: 'categories',
          component: ManageCategoryList,
          name: 'ManageCategoryList',
        },
        {
          path: 'users',
          component: ManageUserList,
          name: 'ManageUserList',
        },
      ],
    },
  ],
}
