<template>
  <div
    v-if="steps && steps.length > 0"
    class="animate__animated animate__fadeIn"
    :style="{ paddingBottom: footerHeight + 'px' }"
  >
    <!-- Slider main container -->
    <div class="swiper swiper-main">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- slides -->
        <div v-for="(step, index) in steps" :key="step.id" class="swiper-slide">
          <div
            v-if="
              isVideo(step) && step.file_path && step?.file_status == 'publish'
            "
            class="position-relative"
          >
            <VideoPlayer
              :ref="'videoPlayerElem' + index"
              :file="step"
              @play-event="videoPlayEvent"
              @ended-event="videoEndedEvent"
            />
            <div
              v-if="step?.file_status != 'publish'"
              class="position-absolute top-0 end-0"
            >
              <span class="badge text-bg-warning m-3">ファイル変換中</span>
            </div>
          </div>
          <div v-else>
            <ImageViewer :file="step" :show_caption="false" />
          </div>
          <div v-if="step.description" class="p-2 text-pre-line">
            {{ step.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav class="navbar fixed-bottom border-0 navbar-dark bg-dark">
    <div class="container-fluid">
      <div class="w-100 d-flex">
        <div class="operation-buttons">
          <button @click="prevSlide" class="btn btn-secondary" type="button">
            前
          </button>
          <button @click="nextSlide" class="btn btn-secondary" type="button">
            次
          </button>
        </div>
        <div class="p-2 text-light text-center flex-grow-1">
          {{ activeIndex + 1 }} / {{ steps.length }}
        </div>
        <div class="operation-buttons">
          <select
            v-model="delayTime"
            class="form-select d-inline-block w-auto align-middle"
            :disabled="isAutoPlay"
          >
            <option v-for="ms in delayTimes" :key="ms" :value="ms">
              {{ ms / 1000 }}秒
            </option>
          </select>
          <button
            v-if="isAutoPlay"
            @click="stopSlider"
            class="btn btn-outline-secondary"
            type="button"
          >
            自動スライド停止
          </button>
          <button
            v-else
            @click="startSlider"
            class="btn btn-secondary"
            type="button"
          >
            自動スライド開始
          </button>
          <button
            @click="closeFullscreen"
            class="btn btn-secondary"
            type="button"
          >
            閉じる
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Swiper, { Autoplay, EffectFade } from 'swiper'
import { isVideo } from '@/utils/fileTypeUtils.js'
import ImageViewer from '@/components/AppImageViewer.vue'
import VideoPlayer from '@/components/AppVideoPlayer.vue'

export default {
  name: 'ManualFullscreen',
  components: {
    ImageViewer,
    VideoPlayer,
  },

  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
  emits: ['displayChange'],

  data() {
    return {
      activeIndex: 0,
      delayTime: 4000,
      delayTimes: [0, 2000, 4000, 6000, 8000, 10000],
      isAutoPlay: false,
      isPlayingVideo: false,
      footerHeight: 56,
      swiper: null,
    }
  },

  async mounted() {
    await this.resize()
    window.addEventListener('resize', this.resize)
    await this.setUpSlider()
  },
  unmounted() {
    this.disposeVideo()
  },

  methods: {
    isVideo,
    resize() {
      this.footerHeight = window.document.querySelector('.navbar').clientHeight
    },
    setUpSlider() {
      this.swiper = new Swiper('.swiper-main', {
        allowTouchMove: true,
        modules: [Autoplay, EffectFade],
        autoplay: {
          enabled: false,
          delay: this.delayTime,
          stopOnLastSlide: true,
        },
        effect: 'fade',
        // https://swiperjs.com/swiper-api#events
        on: {
          slideChangeTransitionStart: () => {
            if (this.isPlayingVideo) {
              this.stopVideo()
            }
          },
          slideChange: () => {
            if (!this.swiper.autoplay.running) {
              this.isAutoPlay = false
            }
            this.activeIndex = this.swiper.activeIndex
            this.changedSlider()
            if (this.swiper.isEnd) {
              this.isAutoPlay = false
            }
          },
        },
      })
    },
    async closeFullscreen() {
      await this.cleanupSlider()
      await this.$emit('displayChange')
    },
    cleanupSlider() {
      this.isAutoPlay = false
      this.isPlayingVideo = false
      this.swiper.destroy()
    },
    prevSlide() {
      this.swiper.slidePrev()
    },
    nextSlide() {
      this.swiper.slideNext()
    },
    startSlider() {
      if (this.swiper.isEnd) {
        this.swiper.slideTo(0)
      }
      this.isAutoPlay = true
      this.swiper.params.autoplay.delay = this.delayTime
      this.swiper.autoplay.start()
      this.changedSlider()
    },
    stopSlider() {
      this.swiper.autoplay.stop()
      this.isAutoPlay = false
    },
    async changedSlider() {
      if (this.isAutoPlay) {
        const activeStep = this.steps[this.activeIndex]
        if (isVideo(activeStep)) {
          await this.swiper.autoplay.stop()
          if (this.isPlayingVideo) {
            await this.stopVideo()
          }
          await this.playVideo()
        }
      }
    },
    playVideo() {
      if (!this.isPlayingVideo) {
        const player = this.$refs['videoPlayerElem' + this.activeIndex][0]
        if (player) {
          player.play()
        }
      }
    },
    stopVideo() {
      const videoPlayerElements = Object.keys(this.$refs)
        .filter((refName) => refName.startsWith('videoPlayerElem'))
        .map((refName) => this.$refs[refName])

      videoPlayerElements.forEach((elem) => {
        const player = elem[0]
        if (player) {
          player.resetPlayer()
        }
      })
      this.isPlayingVideo = false
    },
    disposeVideo() {
      const videoPlayerElements = Object.keys(this.$refs)
        .filter((refName) => refName.startsWith('videoPlayerElem'))
        .map((refName) => this.$refs[refName])

      videoPlayerElements.forEach((elem) => {
        const player = elem[0]
        if (player) {
          player.dispose()
        }
      })
    },
    videoPlayEvent() {
      this.isPlayingVideo = true
    },
    videoEndedEvent() {
      this.isPlayingVideo = false
      if (this.isAutoPlay) {
        this.swiper.autoplay.start()
      }
    },
  },
}
</script>

<style scoped>
.operation-buttons button {
  margin-left: 5px;
}
.operation-buttons :first-child {
  margin-left: 0;
}

.swiper-main .swiper-slide {
  opacity: 0 !important;
}

.swiper-main .swiper-slide-active {
  opacity: 1 !important;
}
</style>
