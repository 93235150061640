<template>
  <div id="appWrap">
    <Alert />
    <router-view></router-view>
    <Loading />
  </div>
</template>

<script>
import Alert from '@/components/AppAlert.vue'
import Loading from '@/components/AppLoading.vue'

export default {
  name: 'App',
  components: {
    Alert,
    Loading,
  },
}
</script>

<style>
#appWrap {
  min-height: 100vh;
}

.table > tbody > tr > th,
.table > thead > tr > th {
  text-align: center;
}
.table-row > tbody > tr > th,
.table-row > thead > tr > th {
  text-align: left;
}
.table-responsive > .table > tbody > tr > td,
.table-responsive > .table > tbody > tr > th,
.table-responsive > .table > thead > tr > td,
.table-responsive > .table > thead > tr > th {
  white-space: nowrap;
}

.text-pre-line {
  white-space: pre-line;
}
</style>
