<template>
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="mb-3">
            <label for="name" class="form-label"
              >カテゴリー名<span
                class="ms-2 small"
                :class="{ 'text-danger': formErrors?.name }"
                >*必須項目です</span
              ></label
            >
            <input
              v-model="formData.name"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': formErrors?.name }"
              id="name"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary"
            type="button"
            @click.prevent="formCancel"
          >
            キャンセル
          </button>
          <button
            class="btn btn-dark"
            type="button"
            @click.prevent="formSubmit"
          >
            更新
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { randomString } from '@/utils/stringUtils.js'

export default {
  name: 'CategoryForm',
  components: {},

  emits: ['hiddenModal'],

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
    }
  },
  computed: {
    modalId: function () {
      return 'categoryForm-' + this.randomString()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formData = {}
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    ...mapActions({
      postOrPatch: 'manage_manual_category/postOrPatch',
      clearAll: 'manage_manual_category/clearAll',
    }),
    showModal(category) {
      if (category) {
        this.formData = Object.assign({}, category)
      }
      this.modal.show()
    },
    async formSubmit() {
      try {
        await this.postOrPatch(this.formData)
        await this.modal.hide()
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            const errorData = e.response.data[key]
            if (Array.isArray(errorData)) {
              this.formErrors[key] = errorData.join('\n')
            } else {
              this.formErrors[key] = errorData
            }
          })
        }
      }
    },
    formCancel() {
      this.modal.hide()
    },
  },
}
</script>
