<template>
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row g-3">
            <div class="col-12">
              <label for="create_username" class="form-label"
                >ユーザー名<span
                  class="ms-2 small"
                  :class="{ 'text-danger': formErrors?.username }"
                  >*必須項目</span
                ></label
              >
              <input
                v-model="formData.username"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.username }"
                id="create_username"
              />
              <span v-if="formErrors?.username" class="invalid-feedback">{{
                formErrors?.username
              }}</span>
            </div>
            <div class="col-12">
              <label for="create_password" class="form-label">パスワード</label>
              <input
                v-model="formData.password"
                type="password"
                class="form-control"
                :class="{ 'is-invalid': formErrors?.password }"
                id="create_password"
              />
              <span v-if="formErrors?.password" class="invalid-feedback">{{
                formErrors?.password
              }}</span>
            </div>
            <div class="col-6">
              <label for="create_last_name" class="form-label">姓</label>
              <input
                v-model="formData.last_name"
                type="text"
                class="form-control"
                id="create_last_name"
              />
            </div>
            <div class="col-6">
              <label for="create_first_name" class="form-label">名</label>
              <input
                v-model="formData.first_name"
                type="text"
                class="form-control"
                id="create_first_name"
              />
            </div>
            <div v-if="groups.length > 0" class="col-12">
              <label for="create_group_id" class="form-label"
                >権限グループ<span
                  class="ms-2 small"
                  :class="{ 'text-danger': formErrors?.group_id }"
                  >*必須項目</span
                ></label
              >
              <select
                v-model="formData.group_id"
                class="form-select"
                :class="{
                  'is-invalid': formErrors?.group_id,
                }"
                id="create_group_id"
              >
                <option v-for="group in groups" :key="group" :value="group.id">
                  {{ group.name }}
                </option>
              </select>
              <span v-if="formErrors?.group_id" class="text-danger small">{{
                formErrors?.group_id
              }}</span>
            </div>
            <div class="col-12">
              <div class="form-check form-switch">
                <input
                  v-model="formData.is_active"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="userIsActive"
                />
                <label class="form-check-label" for="userIsActive">有効</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span
            v-if="Object.keys(formErrors).length > 0"
            class="text-danger small"
            >入力内容を確認してください</span
          >
          <button
            class="btn btn-secondary"
            type="button"
            @click.prevent="formCancel"
          >
            キャンセル
          </button>
          <button
            class="btn btn-dark"
            type="button"
            @click.prevent="formSubmit"
          >
            保存
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { randomString } from '@/utils/stringUtils.js'

export default {
  name: 'UserForm',
  components: {},

  emits: ['hiddenModal', 'afterSubmit'],
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    postOrPatch: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      modal: null,
      formData: {
        is_active: true,
      },
      formErrors: {},
    }
  },
  computed: {
    modalId: function () {
      return 'categoryForm-' + this.randomString()
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formData = {}
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    randomString,
    showModal(user) {
      if (user) {
        this.formData = Object.assign({}, user)
      }
      this.modal.show()
    },
    async formSubmit() {
      try {
        await this.postOrPatch(this.formData)
        await this.$emit('afterSubmit')
        await this.modal.hide()
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            if (key == 'data_limit') {
              this.modal.hide()
              this.$store.dispatch('alert/setErrorMessage', {
                message: e.response.data[key].join('\n'),
              })
            } else {
              const errorData = e.response.data[key]
              if (Array.isArray(errorData)) {
                this.formErrors[key] = errorData.join('\n')
              } else {
                this.formErrors[key] = errorData
              }
            }
          })
        }
      }
    },
    formCancel() {
      this.modal.hide()
    },
  },
}
</script>
