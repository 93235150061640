<template>
  <div class="animate__animated animate__fadeIn">
    <div class="container-fluid bg-dark">
      <div class="row min-vh-100">
        <div class="col-md p-3 d-flex align-items-center">
          <div id="canvasWrap">
            <canvas
              id="canvas"
              ref="canvas"
              class="border"
              width="1200"
              height="900"
            ></canvas>
          </div>
        </div>
        <div
          class="col-md-auto bg-dark border-start border-secondary d-flex align-items-center"
        >
          <nav class="nav flex-column">
            <div class="nav-link py-2 px-0 dropdown">
              <button
                type="button"
                class="btn text-light fs-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <i class="bi bi-palette" :style="getCssColor(currentColor)"></i>
              </button>
              <div class="dropdown-menu p-4" style="width: 300px">
                <div class="row g-1">
                  <div v-for="(color, index) in colorSet" class="col-3">
                    <div
                      class="text-center border fs-2"
                      @click="changeColor(color)"
                    >
                      <i
                        class="bi bi-square-fill"
                        :style="getCssColor(color)"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="nav-link py-2 px-0">
              <button
                @click="drawMode"
                type="button"
                class="btn text-light fs-2"
                :class="{ 'btn-light text-dark': isDrawingMode }"
              >
                <i v-if="isDrawingMode" class="bi bi-pencil-fill"></i>
                <i v-else class="bi bi-pencil"></i>
              </button>
            </div>
            <div class="nav-link py-2 px-0 dropdown">
              <button
                type="button"
                class="btn text-light fs-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <i class="bi bi-star"></i>
              </button>
              <div class="dropdown-menu p-4" style="width: 300px">
                <div class="row gy-3">
                  <div
                    v-for="(stamp, index) in stamps"
                    :key="index"
                    class="col-4"
                  >
                    <button
                      type="button"
                      class="stamp btn btn-light w-100 py-3"
                      @click="addStamp(stamp)"
                    >
                      <img :src="stamp" width="20" />
                    </button>
                  </div>
                  <div class="col-4">
                    <button
                      type="button"
                      class="stamp btn btn-light w-100 py-3"
                      @click="addCircle"
                    >
                      <i class="bi bi-circle"></i>
                    </button>
                  </div>
                  <div class="col-4">
                    <button
                      type="button"
                      class="stamp btn btn-light w-100 py-3"
                      @click="addSquare"
                    >
                      <i class="bi bi-square"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="nav-link py-2 px-0 dropdown">
              <button
                type="button"
                class="btn text-light fs-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <i class="bi bi-input-cursor-text"></i>
              </button>
              <form class="dropdown-menu p-4" style="width: 300px">
                <div class="input-group">
                  <input
                    v-model="inputText"
                    type="text"
                    class="form-control"
                    placeholder="文字を入力"
                    aria-describedby="button-addon2"
                  />
                  <button
                    @click="addText"
                    class="btn btn-outline-secondary"
                    type="button"
                  >
                    <i class="bi bi-check-lg"></i>
                  </button>
                </div>
              </form>
            </div>
            <div class="nav-link py-2 px-0">
              <button
                @click="deleteObject"
                type="button"
                class="btn text-light fs-2"
              >
                <i class="bi bi-trash"></i>
              </button>
            </div>
            <hr class="border border-light" />
            <div class="nav-link py-2 px-0">
              <button
                @click="saveImage"
                type="button"
                class="btn text-light fs-2"
              >
                <i class="bi bi-save"></i>
              </button>
            </div>
            <div class="nav-link py-2 px-0">
              <button
                @click="closeEditor"
                type="button"
                class="btn text-light fs-2"
              >
                <i class="bi bi-x-circle"></i>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fabric } from 'fabric'

export default {
  name: 'ImageEditor',
  components: {},

  emits: ['captureEditor', 'hiddenEditor'],
  props: {
    file_data: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFabricCanvas: false,
      colorSet: [
        '#FFFFFF', // 白
        '#FFD700', // ゴールデンイエロー
        '#FFA500', // オレンジ
        '#9ACD32', // イエローグリーン
        '#008000', // 緑
        '#99CCFF', // パステルブルー
        '#0048BA', // オーシャンブルー
        '#FF99CC', // パステルピンク
        '#FF0000', // 赤
        '#800080', // 紫
        '#999999', // グレー
        '#000000', // 黒
      ],
      currentColor: '#ffffff',
      inputText: '',
      isDrawingMode: false,
      stamps: [
        '/stamps/attention.svg',
        '/stamps/star.svg',
        '/stamps/fukidashi.svg',
        '/stamps/arrow.svg',
        '/stamps/finger.svg',
        '/stamps/like.svg',
        '/stamps/cross.svg',
      ],
    }
  },

  mounted() {
    this.canvasInit()
  },
  unmounted() {
    this.canvasClear()
  },

  watch: {
    currentColor: function (newColor, oldColor) {
      if (this.isDrawingMode) {
        const canvas = document.getElementById('canvas').fabric
        canvas.freeDrawingBrush.color = newColor
      }
      this.setColor()
    },
  },

  methods: {
    canvasInit() {
      if (!this.isFabricCanvas) {
        const canvas = new fabric.Canvas('canvas')
        document.getElementById('canvas').fabric = canvas
        this.isFabricCanvas = true
      }
      this.resizeToCanvas()
    },
    canvasClear() {
      if (!!document.getElementById('canvas')) {
        const canvas = document.getElementById('canvas').fabric
        if (typeof canvas != 'undefined') {
          this.isDrawingMode = true
          this.drawMode()
          canvas.clear()
          document.getElementById('canvas').fabric = canvas
        }
      }
    },
    saveImage() {
      const canvas = document.getElementById('canvas')
      const fabricCanvas = canvas.fabric
      fabricCanvas.discardActiveObject()
      fabricCanvas.renderAll()
      this.$emit('captureEditor', canvas.toDataURL('image/webp'), 'image/webp')
      this.canvasClear()
    },
    closeEditor() {
      this.canvasClear()
      this.$emit('hiddenEditor')
    },
    setBackground() {
      if (this.file_data) {
        const canvas = document.getElementById('canvas').fabric
        const canvasCenter = canvas.getCenter()

        fabric.Image.fromURL(
          this.file_data,
          (object) => {
            object.scaleToHeight(canvas.height)
            canvas.setBackgroundImage(object, canvas.renderAll.bind(canvas), {
              top: canvasCenter.top,
              left: canvasCenter.left,
              originX: 'center',
              originY: 'center',
            })
          },
          { crossOrigin: 'Anonymous' }
        )
      }
    },
    resizeToCanvas() {
      if (!this.isFabricCanvas) return

      const wrap = document.getElementById('canvasWrap')
      const canvas = document.getElementById('canvas').fabric
      canvas.setDimensions({
        width: wrap.clientWidth - 1,
        height: (wrap.clientWidth / 16) * 9 - 1,
      })
      this.setBackground()
    },
    drawMode() {
      this.isDrawingMode = !this.isDrawingMode

      const canvas = document.getElementById('canvas').fabric
      canvas.isDrawingMode = this.isDrawingMode
      canvas.freeDrawingBrush.color = this.currentColor
      canvas.freeDrawingBrush.width = 5
    },
    addText() {
      if (this.inputText) {
        let shape = new fabric.Text(this.inputText, {
          fontFamily: 'Arial',
          fontSize: 24,
          fontWeight: 'bold',
          fill: this.currentColor,
        })
        this.addShape(shape)
        this.inputText = ''
      }
    },
    addStamp(stampPath) {
      fabric.loadSVGFromURL(stampPath, (objects, options) => {
        let shape = fabric.util.groupSVGElements(objects, options)
        shape.set({
          scaleX: 0.1,
          scaleY: 0.1,
        })
        this.addShape(shape)
        this.setColor()
      })
    },
    addCircle() {
      let shape = new fabric.Ellipse({
        rx: 50,
        ry: 50,
        fill: 'transparent',
        stroke: this.currentColor,
        strokeWidth: 3,
        strokeUniform: true,
      })
      this.addShape(shape)
    },
    addSquare() {
      let shape = new fabric.Rect({
        width: 100,
        height: 100,
        fill: 'transparent',
        stroke: this.currentColor,
        strokeWidth: 3,
        strokeUniform: true,
      })
      this.addShape(shape)
    },
    addShape(shape) {
      const canvas = document.getElementById('canvas').fabric
      canvas.add(shape).setActiveObject(shape)
      shape.center()
    },
    deleteObject() {
      const canvas = document.getElementById('canvas').fabric
      const objects = canvas.getActiveObjects()
      for (const object of objects) {
        canvas.remove(object)
      }
    },
    setColor() {
      const canvas = document.getElementById('canvas').fabric
      const objects = canvas.getActiveObjects()

      for (const object of objects) {
        if ('_objects' in object) {
          for (const obj of object._objects) {
            this._setActiveStyle(obj, this.currentColor)
          }
        } else {
          this._setActiveStyle(object, this.currentColor)
        }
      }
      canvas.renderAll()
    },
    _setActiveStyle(object, value) {
      if (
        'stroke' in object &&
        object.stroke != 'transparent' &&
        object.stroke != null
      ) {
        object.set('stroke', value)
      }
      if (
        'fill' in object &&
        object.fill != 'transparent' &&
        object.fill != null
      ) {
        object.set('fill', value)
      }
      object.setCoords()
    },
    changeColor(color) {
      this.currentColor = color
    },
    getCssColor(color) {
      return `color: ${color};`
    },
  },
}
</script>

<style scoped>
#canvasWrap {
  width: 100%;
}
@media (min-width: 768px) {
  #canvasWrap {
    width: calc(100% - 75px);
  }
}
</style>
