<template>
  <router-view v-if="is_authorized"></router-view>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ManageHome',

  data() {
    return {
      is_authorized: false,
    }
  },
  computed: {
    ...mapGetters({
      hasPermission: 'auth/hasPermission',
    }),
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.hasPermission('manage')) {
        vm.$store.dispatch('alert/setErrorMessage', {
          message: 'このページへのアクセス権がありません。',
        })
        window.setTimeout(() => {
          vm.$router.push({ path: '/' })
        }, vm.$store.getters['alert/timeout'])
      } else {
        vm.is_authorized = true
      }
    })
  },
}
</script>
