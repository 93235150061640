<template>
  <ManageNavbar />
  <header class="bg-light border-bottom">
    <div class="container-fluid px-4">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="m-0 py-3 py-sm-4">ユーザー管理</h3>
        <div class="operation_links">
          <a @click.prevent="showUserForm()" class="btn btn-primary"
            ><i class="bi bi-plus"></i>
            <span class="d-none d-sm-inline-block ms-1"
              >ユーザーを作成する</span
            ></a
          >
        </div>
      </div>
    </div>
  </header>

  <main>
    <div class="container-fluid px-4 pb-4">
      <div class="d-flex align-items-center justify-content-between pt-3 pb-2">
        <a
          class="btn btn-light"
          :class="{ 'btn-dark text-light': is_search }"
          data-bs-toggle="offcanvas"
          data-bs-target="#searchBoxOffcanvas"
          aria-controls="searchBoxOffcanvas"
          ><i class="bi bi-search"></i
        ></a>
        <div class="d-flex justify-content-start gap-2 mb-2">
          <Pagination
            :count="count"
            :prev="prev"
            :next="next"
            :current_page="current_page"
            :total_pages="total_pages"
            :page_range="5"
            :show_count="true"
            @move-page="movePage"
          />
        </div>
      </div>

      <!-- users -->
      <div v-if="users && users.length > 0">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="col-sm-2">氏名</th>
                <th scope="col" class="col-sm-2">ユーザー名</th>
                <th scope="col" class="col-sm-2">権限グループ</th>
                <th scope="col" class="col-sm-1">有効</th>
                <th scope="col" class="col-sm-2">作成日時</th>
                <th scope="col" class="col-sm-2">最終ログイン日時</th>
                <th scope="col" class="col-sm-1"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td scope="row">{{ user.full_name }}</td>
                <td>{{ user.username }}</td>
                <td>{{ user.groups.join(', ') }}</td>
                <td class="text-center">
                  <ActiveStatus :is_active="user.is_active" />
                </td>
                <td class="text-end">{{ user.date_joined }}</td>
                <td class="text-end">{{ user.last_login }}</td>
                <td class="text-end">
                  <span v-if="user.id != userData('id')">
                    <a
                      href="#"
                      v-on:click.prevent="showUserForm(user)"
                      class="text-success"
                      >編集</a
                    >｜<a
                      href="#"
                      v-on:click.prevent="removeData(user.id)"
                      class="text-danger"
                      >削除</a
                    >
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p v-else class="mt-3">該当するデータはありません。</p>
    </div>
  </main>
  <div
    class="offcanvas offcanvas-start"
    data-bs-backdrop="static"
    tabindex="-1"
    id="searchBoxOffcanvas"
    aria-labelledby="searchBoxLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="searchBoxLabel">ユーザー検索</h5>
      <button
        type="button"
        id="searchBoxClose"
        class="btn-close d-none"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div class="card card-body">
        <form>
          <div class="mb-4">
            <label class="text-muted mb-1" for="searchUsername"
              >ユーザー名</label
            >
            <input
              v-model="query.username"
              type="text"
              class="form-control"
              id="searchUsername"
            />
          </div>
          <div class="mb-4">
            <label class="text-muted mb-1" for="searchGroup"
              >権限グループ</label
            >
            <select
              v-model="query.group_id"
              class="form-select"
              id="searchGroup"
            >
              <option :value="undefined">---</option>
              <option v-for="group in groups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>

          <button
            class="w-100 btn btn-dark mb-2"
            v-on:click.prevent="getQueryResult"
          >
            検索
          </button>
          <button
            class="w-100 btn btn-secondary"
            v-on:click.prevent="resetQuery"
          >
            クリア
          </button>
        </form>
      </div>
    </div>
  </div>

  <UserForm
    ref="userForm"
    :groups="groups"
    :post-or-patch="postOrPatch"
    @after-submit="submittedUserForm"
  />
  <Confirm ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ManageNavbar } from '@/components/navbar'
import Pagination from '@/components/AppPagination.vue'
import ActiveStatus from '@/components/AppActiveStatus.vue'
import Confirm from '@/components/AppConfirm.vue'
import UserForm from '@/components/UserForm.vue'

export default {
  name: 'ManageUserList',
  components: {
    ManageNavbar,
    Pagination,
    ActiveStatus,
    Confirm,
    UserForm,
  },

  data() {
    return {
      current_user_id: null,
      query: {},
    }
  },
  computed: {
    ...mapGetters({
      users: 'manage_user/users',
      count: 'manage_user/count',
      prev: 'manage_user/prev',
      next: 'manage_user/next',
      current_page: 'manage_user/current_page',
      total_pages: 'manage_user/total_pages',
      query_params: 'manage_manual/query_params',
      groups: 'manage_user/group_list',
      userData: 'auth/userData',
    }),
    is_search: function () {
      let search_query = Object.assign({}, this.query)
      delete search_query['page']
      return !!Object.keys(search_query).length
    },
  },

  async created() {
    await this.getData()
    await this.getGroupList()
    this.query = this.query_params
  },

  methods: {
    ...mapActions({
      setQuery: 'manage_user/setQuery',
      getData: 'manage_user/fetchAll',
      getGroupList: 'manage_user/fetchGroupList',
      postOrPatch: 'manage_user/postOrPatch',
      deleteData: 'manage_user/deleteData',
    }),
    movePage(page) {
      this.setQuery(Object.assign(this.query, { page: page })).then(() => {
        this.getData()
      })
    },
    getQueryResult() {
      this.setQuery(this.query).then(() => {
        this.getData()
        document.querySelector('#searchBoxClose').click()
      })
    },
    resetQuery() {
      this.query = {}
      this.getQueryResult()
    },
    showUserForm(data) {
      this.$refs.userForm.showModal(data)
    },
    submittedUserForm() {
      this.getData()
      this.getGroupList()
    },
    removeData(id) {
      this.current_user_id = id
      this.$refs.confirmDataDelete.showConfirm(
        'ユーザーを削除',
        'このユーザーを削除しますか？'
      )
    },
    async confirmDataDelete() {
      if (this.current_user_id == this.userData('id')) {
        return
      }

      await this.deleteData(this.current_user_id)
      await this.getData()
    },
  },
}
</script>
