import PublicManualDetail from '@/pages/public/ManualDetail.vue'

export default {
  routes: [
    {
      path: '/p/manuals/:slug([a-f0-9-]+)',
      component: PublicManualDetail,
      name: 'PublicManualDetail',
      props: (route) => ({ slug: route.params.slug }),
    },
  ],
}
