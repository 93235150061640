<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col" class="col-sm-1">公開種別</th>
          <th scope="col" class="col-sm-3">タイトル</th>
          <th scope="col" class="col-sm-3">カテゴリー</th>
          <th scope="col" class="col-sm-2">更新ユーザー</th>
          <th scope="col" class="col-sm-3">更新日時</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="manual in manuals" :key="manual.id" class="text-nowrap">
          <td class="small text-center">
            {{ manual.status_name }}
          </td>
          <td>
            <a
              href="#"
              v-on:click.prevent="detailLink(manual)"
              class="link-secondary"
              >{{ manual.title }}</a
            >
          </td>
          <td class="small">
            {{ manual.categories.map((obj) => obj.name).join(', ') }}
          </td>
          <td class="text-center">
            <span v-if="manual.author">{{ manual.author }}</span
            ><span v-else class="text-muted">削除済みユーザー</span>
          </td>
          <td class="text-end">{{ manual.updated_at }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ManualDataList',
  components: {},

  emits: ['detailLink'],
  props: {
    manuals: {
      type: Array,
      required: true,
    },
  },

  methods: {
    detailLink(manual) {
      this.$emit('detailLink', manual)
    },
  },
}
</script>
