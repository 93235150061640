<template>
  <BaseNavbar :page_navigation="navigation" />
</template>

<script>
import BaseNavbar from '@/components/navbar/BaseNavbar.vue'

export default {
  name: 'MypageNavbar',
  components: {
    BaseNavbar,
  },

  data() {
    return {
      navigation: [
        {
          name: 'マニュアル管理',
          to: {
            name: 'MypageManualList',
          },
        },
      ],
    }
  },
}
</script>
