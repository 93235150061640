<template>
  <ManageNavbar />
  <header class="bg-light border-bottom">
    <div class="container-fluid px-4">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="m-0 py-3 py-sm-4">カテゴリー管理</h3>
        <div class="operation_links">
          <a @click.prevent="showCategoryForm" class="btn btn-primary"
            ><i class="bi bi-plus"></i>
            <span class="d-none d-sm-inline-block ms-1"
              >カテゴリーを作成する</span
            ></a
          >
        </div>
      </div>
    </div>
  </header>

  <main>
    <div class="container-fluid p-4">
      <div
        v-if="category_list && category_list.length > 0"
        class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2 g-lg-3"
      >
        <div
          v-for="(category, index) in category_list"
          :key="category.id"
          class="col"
        >
          <div class="card card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <svg
                  class="bd-placeholder-img flex-shrink-0 me-3"
                  width="32"
                  height="32"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 32x32"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <circle cy="16" cx="16" r="16" fill="#666" />
                  <text
                    x="50%"
                    y="50%"
                    fill="#fff"
                    text-anchor="middle"
                    dy=".3em"
                  >
                    {{ category.name.slice(0, 1) }}
                  </text>
                </svg>
              </div>
              <div class="flex-grow-1">
                <strong
                  ><a
                    href="#"
                    @click="manualLink(category)"
                    class="link-secondary link-text"
                    >{{ category.name }}</a
                  ></strong
                >
              </div>
              <div class="text-nowrap">
                <a
                  class="btn btn-sm btn-success mx-2"
                  @click.prevent="showCategoryForm(index)"
                  ><i class="bi bi-pencil"></i
                ></a>
                <a
                  class="btn btn-sm btn-danger"
                  @click.prevent="removeData(index)"
                  ><i class="bi bi-trash"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p v-else class="mt-3">該当するデータはありません。</p>
    </div>
    <Confirm ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
    <ManageCategoryForm ref="modal" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Confirm from '@/components/AppConfirm.vue'
import { ManageNavbar } from '@/components/navbar'
import ManageCategoryForm from '@/components/manual/ManualCategoryForm.vue'

export default {
  name: 'ManageCategoryList',
  components: {
    Confirm,
    ManageNavbar,
    ManageCategoryForm,
  },

  computed: {
    ...mapGetters({
      category_list: 'manage_manual_category/list',
    }),
  },

  date() {
    return {
      current_category_id: null,
    }
  },

  async created() {
    await this.getCategoryList()
  },

  methods: {
    ...mapActions({
      getCategoryList: 'manage_manual_category/fetchList',
      deleteData: 'manage_manual_category/deleteData',
      manualSetQuery: 'manage_manual/setQuery',
    }),
    showCategoryForm(index) {
      this.$refs.modal.showModal(this.category_list[index])
    },
    manualLink(category) {
      this.manualSetQuery({ category: category.id }).then(() => {
        this.$router.push({
          name: 'ManageManualList',
        })
      })
    },
    removeData(index) {
      this.current_category_id = this.category_list[index].id
      this.$refs.confirmDataDelete.showConfirm(
        'カテゴリー削除',
        'このカテゴリーを削除しますか？'
      )
    },
    async confirmDataDelete() {
      await this.deleteData(this.current_category_id)
      await this.getCategoryList()
    },
  },
}
</script>
