<template>
  <ManualFullscreen
    ref="manualFullscreen"
    v-if="is_fullscreen"
    @display-change="displayChange"
    :steps="manual.steps"
  />
  <div v-else class="animate__animated animate__fadeIn">
    <!--Header-->
    <header class="d-flex align-items-center border-bottom bg-white mb-3">
      <div class="header-img-container">
        <ImageViewer
          :file="{
            file_path: manual.file_path,
            file_type: manual.file_type,
          }"
          :show_caption="false"
          :show_lightbox="false"
        />
      </div>
      <div class="ms-3 me-auto">
        <h5 class="mb-1 d-none d-sm-block">
          {{ textWrap(manual.title, 15) }}
        </h5>
        <ul class="list-inline small text-muted m-0 d-none d-sm-block">
          <li class="list-inline-item">
            <i class="bi bi-globe me-1"></i>{{ manual.status_name }}
          </li>
          <li class="list-inline-item">
            <i class="bi bi-person me-1"></i>{{ manual.author }}
          </li>
          <li class="list-inline-item">
            <i class="bi bi-arrow-repeat me-1"></i>{{ manual.updated_at }}
          </li>
        </ul>
      </div>
      <div class="me-1">
        <button type="button" class="btn btn-outline-dark me-1" role="button">
          <i class="bi bi-arrows-fullscreen" @click.prevent="displayChange"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-dark me-1"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="true"
        >
          <i class="bi bi-three-dots"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li>
            <a
              class="dropdown-item link-text"
              data-bs-toggle="offcanvas"
              data-bs-target="#manualInfoOffcanvas"
              aria-controls="manualInfoOffcanvas"
              >情報を見る</a
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'MypageManualEdit',
                params: { id: Number(manual.id) },
              }"
              class="dropdown-item"
              >編集する</router-link
            >
          </li>
          <li><hr class="dropdown-divider" /></li>
          <li>
            <a
              class="dropdown-item link-text link-danger"
              @click.prevent="removeData()"
              >削除する</a
            >
          </li>
        </ul>
        <router-link
          :to="{
            name: 'MypageManualList',
          }"
          class="btn btn-light me-1"
          ><i class="bi bi-x-lg"></i
        ></router-link>
      </div>
    </header>

    <main>
      <!--Step Data-->
      <div class="container-fluid">
        <ManualStepData
          ref="manualStepData"
          v-if="manual.steps && manual.steps.length > 0"
          :steps="manual.steps"
        />
        <div v-else class="text-center">
          <p>データがありません。</p>
        </div>
      </div>

      <!--Information-->
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="manualInfoOffcanvas"
        aria-labelledby="manualInfoLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="manualInfoLabel">マニュアル情報</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div class="mb-2">
            <ImageViewer
              :file="{
                file_path: manual.file_path,
                file_type: manual.file_type,
              }"
              :show_caption="false"
              :show_lightbox="false"
            />
          </div>
          <div class="card card-body rounded-0">
            <dl class="m-0">
              <dt>タイトル</dt>
              <dd class="mb-3">{{ manual.title }}</dd>
              <dt>説明文</dt>
              <dd class="mb-3 text-pre-line">{{ manual.summary }}</dd>
              <dt>カテゴリー</dt>
              <dd class="mb-3">{{ category_names }}</dd>
              <dt>更新ユーザー</dt>
              <dd class="mb-3">{{ manual.author }}</dd>
              <dt>更新日時</dt>
              <dd class="mb-3">{{ manual.updated_at }}</dd>
              <dt>公開種別</dt>
              <dd class="mb-0">{{ manual.status_name }}</dd>
            </dl>
          </div>
          <div class="mt-2" v-if="manual.slug">
            <ManualQRCode :slug="manual.slug" />
          </div>
        </div>
      </div>
    </main>
  </div>

  <Confirm ref="confirmDataDelete" @confirm-true="confirmDataDelete" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { textWrap } from '@/utils/stringUtils.js'
import Confirm from '@/components/AppConfirm.vue'
import ImageViewer from '@/components/AppImageViewer.vue'
import ManualQRCode from '@/components/manual/ManualQRCode.vue'
import { ManualStepData, ManualFullscreen } from '@/components/manual'

export default {
  name: 'MemberManualDetail',
  components: {
    Confirm,
    ImageViewer,
    ManualQRCode,
    ManualStepData,
    ManualFullscreen,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      is_fullscreen: false,
    }
  },
  computed: {
    ...mapGetters({
      manual: 'mypage_manual/detail',
      category_names: 'mypage_manual/category_names',
    }),
  },

  async created() {
    await this.getData(this.id).catch(() => {
      window.setTimeout(() => {
        this.$router.push({
          name: 'MypageManualList',
        })
      }, this.$store.getters['alert/timeout'])
    })
  },

  methods: {
    textWrap,
    ...mapActions({
      getData: 'mypage_manual/fetchData',
      deleteData: 'mypage_manual/deleteData',
    }),
    showFullScreen() {
      if (this.manual.steps.length > 0) {
        this.$refs.screen.showModal()
      } else {
        this.$store.dispatch('alert/setErrorMessage', {
          message: 'データがありません。',
        })
      }
    },
    async displayChange() {
      if (this.manual.steps.length > 0) {
        await (this.is_fullscreen = !this.is_fullscreen)
      } else {
        this.$store.dispatch('alert/setErrorMessage', {
          message: 'データがありません。',
        })
      }
    },
    removeData() {
      this.$refs.confirmDataDelete.showConfirm(
        'マニュアル削除',
        'このマニュアルを削除しますか？'
      )
    },
    async confirmDataDelete() {
      await this.deleteData(this.id)
      this.$router.push({
        name: 'MypageManualList',
      })
    },
  },
}
</script>

<style scoped>
.header-img-container {
  width: 105px;
}

.header-img-container img {
  display: block;
  width: 100%;
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  background-color: #333;
}
</style>
