<template>
  <div v-if="currentView == 'imageCamera'">
    <ManualImageCamera
      ref="manualImageCamera"
      @hidden-camera="startManualEdit"
      @capture-camera="setImageCapture"
    />
  </div>
  <div v-else-if="currentView == 'imageEditor'">
    <ImageEditor
      ref="imageEditor"
      :file_data="original_file_data"
      @hidden-editor="startManualEdit"
      @capture-editor="setEditedImage"
    />
  </div>
  <div v-else-if="currentView == 'videoViewer'">
    <VideoEditor
      ref="videoPlayer"
      :file_path="original_file_data"
      :file_type="original_file_type"
      :hidden-editor="startManualEdit"
      :capture-editor="setEditedImage"
    />
  </div>
  <div v-else-if="currentView == 'videoPreview'">
    <VideoEditor
      ref="videoPlayer"
      :file_path="original_file_data"
      :file_type="original_file_type"
      :hidden-editor="startManualEdit"
    />
  </div>
  <div v-else class="animate__animated animate__fadeIn">
    <!--Header-->
    <div id="header-alert" class="update-header w-100">編集中</div>
    <header class="d-flex align-items-center border-bottom bg-white mb-3">
      <div class="header-img-container">
        <ImageViewer
          :file="{
            file_path: manual.file_path,
            file_type: manual.file_type,
          }"
          :show_caption="false"
          :show_lightbox="false"
        />
      </div>
      <div class="ms-3 me-auto">
        <h5 class="mb-1">
          <span class="d-none d-sm-inline">{{
            textWrap(manual.title, 15)
          }}</span>
          <i
            class="bi bi-pencil ms-1 link-text link-secondary"
            @click.prevent="showManualForm"
          ></i>
        </h5>
        <ul class="list-inline small text-muted m-0 d-none d-sm-block">
          <li class="list-inline-item">
            <i class="bi bi-globe me-1"></i>{{ manual.status_name }}
          </li>
          <li class="list-inline-item">
            <i class="bi bi-person me-1"></i>{{ manual.author }}
          </li>
          <li class="list-inline-item">
            <i class="bi bi-arrow-repeat me-1"></i>{{ manual.updated_at }}
          </li>
        </ul>
      </div>
      <div class="me-1">
        <a class="btn btn-dark" @click.prevent="formSubmit"
          ><span class="d-none d-sm-inline">終了する</span
          ><span class="d-inline d-sm-none"><i class="bi bi-check-lg"></i></span
        ></a>
      </div>
    </header>

    <main id="main">
      <!--Step Data-->
      <div class="container-fluid">
        <ManualStepDataEdit
          ref="manualStepDataEdit"
          :steps="steps"
          :current-step-index="currentStepIndex"
          @current-change="changeCurrentStep"
          @image-capture="selectedImage"
          @video-capture="selectedVideo"
        >
          <template v-slot:add-button>
            <div class="ratio ratio-16x9 bg-secondary">
              <button
                type="button"
                class="btn btn-secondary w-100"
                @click.prevent="handleAddItem"
              >
                <i class="bi bi-plus-circle-fill fs-1"></i>
              </button>
            </div>
          </template>
          <template v-slot:current-operation-icons="{ selectFile }">
            <!-- ファイルがある場合 -->
            <div v-if="currentStep?.file_path">
              <button
                v-if="isVideo({ file_type: currentStep?.file_type })"
                type="button"
                class="btn btn-light ms-1"
                @click.prevent="startVideoPreview"
              >
                <i class="bi bi-eye"></i>
              </button>
              <button
                type="button"
                class="btn btn-light ms-1"
                @click.prevent="deleteFile"
              >
                <i class="bi bi-trash3 text-danger"></i>
              </button>
            </div>
            <!-- ファイルがない場合 -->
            <div v-else>
              <button
                type="button"
                class="btn btn-light ms-1"
                @click.prevent="startImageCamera"
              >
                <i class="bi bi-camera"></i>
              </button>
              <button
                type="button"
                class="btn btn-light ms-1"
                @click.prevent="startSelectFile"
              >
                <i class="bi bi-folder2"></i>
              </button>
            </div>
          </template>
          <template v-slot:thumbnail-operation-icons="{ index }">
            <div class="d-flex justify-content-between p-2">
              <a class="btn btn-sm btn-light" @click="handleUpItem(index)">
                <i class="bi bi-caret-up-fill"></i>
              </a>
              <button
                class="btn btn-sm btn-light"
                @click="handleDownItem(index)"
              >
                <i class="bi bi-caret-down-fill"></i>
              </button>
            </div>
          </template>
          <template v-slot:footer>
            <p>
              <span
                class="link-text link-danger"
                @click.prevent="handleDeleteItem"
                ><i class="bi bi-trash3 text-danger me-1"></i
                >このステップを削除する</span
              >
            </p>
          </template>
        </ManualStepDataEdit>
      </div>
    </main>
  </div>

  <ManualForm
    ref="manualFormModal"
    :categories="categories"
    :statuses="statuses"
    :members="members"
    :post-or-patch="postOrPatch"
  />
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { isImage, isVideo } from '@/utils/fileTypeUtils.js'
import { textWrap } from '@/utils/stringUtils.js'
import ImageViewer from '@/components/AppImageViewer.vue'
import ManualForm from '@/components/manual/ManualForm.vue'
import ManualStepDataEdit from '@/components/manual/ManualStepDataEdit.vue'
import ManualImageCamera from '@/components/manual/ManualImageCamera.vue'
import ImageEditor from '@/components/AppImageEditor.vue'
import VideoEditor from '@/components/AppVideoEditor.vue'

export default {
  name: 'ManageManualEdit',
  components: {
    ImageViewer,
    ManualForm,
    ManualStepDataEdit,
    ManualImageCamera,
    ImageEditor,
    VideoEditor,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      currentStep: {},
      currentView: 'manualEdit',
      original_file_data: null,
      original_file_type: null,
    }
  },
  computed: {
    ...mapGetters({
      manual: 'manage_manual/detail',
      steps: 'manage_manual_step/list',
      currentStepIndex: 'manage_manual_step/current_index',
      categories: 'manage_manual_category/list',
      statuses: 'manage_manual/status_list',
      members: 'manage_user/member_list',
    }),
  },

  watch: {
    currentStepIndex: {
      handler() {
        this.setCurrentStep()
      },
    },
  },

  async created() {
    await this.getManualData(this.id).catch(() => {
      window.setTimeout(() => {
        this.$router.push({
          name: 'ManageManualList',
        })
      }, this.$store.getters['alert/timeout'])
    })
    await this.getStepList(this.id)
    this.getCategoryList()
    this.getStatusList()
    this.getMemberList()
    this.changeCurrentStep(0)
  },

  unmounted() {
    this.clearCurrentIndex()
  },

  methods: {
    isImage,
    isVideo,
    textWrap,
    ...mapMutations({
      addItem: 'manage_manual_step/addItem',
      editItem: 'manage_manual_step/editItem',
      deleteItem: 'manage_manual_step/deleteItem',
      setCurrentIndex: 'manage_manual_step/setCurrentIndex',
      clearCurrentIndex: 'manage_manual_step/clearCurrentIndex',
    }),
    ...mapActions({
      getManualData: 'manage_manual/fetchData',
      getStepList: 'manage_manual_step/fetchList',
      upItem: 'manage_manual_step/upItem',
      downItem: 'manage_manual_step/downItem',
      clearAll: 'manage_manual/clearAll',
      bulkPostOrPatch: 'manage_manual_step/bulkPostOrPatch',
      uploadItemFile: 'manage_manual_step/uploadFile',
      getCategoryList: 'manage_manual_category/fetchList',
      getStatusList: 'manage_manual/fetchStatusList',
      getMemberList: 'manage_user/fetchMemberList',
      postOrPatch: 'manage_manual/postOrPatch',
    }),
    setCurrentStep() {
      this.currentStep = this.steps[this.currentStepIndex]
    },
    showManualForm() {
      this.$refs.manualFormModal.showModal(this.manual)
    },
    /**
     * Steps フォーム操作
     */
    async formSubmit() {
      try {
        await this.bulkPostOrPatch(this.steps)
        await this.getManualData(this.id)
        await this.$router.push({
          name: 'ManageManualDetail',
          params: { id: this.id },
        })
      } catch (e) {
        if (e.response.status == 400) {
          // サーバー側の検証エラーを表示
          let errors = {}
          Object.keys(e.response.data).forEach((key) => {
            errors[key] = e.data[key][0]
          })
          // this.$formkit.setErrors(this.form_id, [], errors)
        }
      }
    },
    /**
     * Steps 配列操作
     */
    changeCurrentStep(index) {
      if (this.steps && index in this.steps) {
        this.setCurrentIndex(index)
      } else {
        this.clearCurrentIndex()
      }
    },
    handleAddItem() {
      this.addItem()
      this.changeCurrentStep(this.steps.length - 1)
    },
    handleDeleteItem() {
      this.deleteItem()
      this.$refs.manualStepDataEdit.setCurrentStep()
      this.changeCurrentStep(0)
    },
    async handleUpItem(index) {
      let newIndex = await this.upItem(index)
      this.changeCurrentStep(newIndex)
    },
    async handleDownItem(index) {
      let newIndex = await this.downItem(index)
      this.changeCurrentStep(newIndex)
    },
    /**
     * Step データ操作
     */
    async setEditedImage(dataURL, fileType) {
      // 保存処理
      await this.uploadItemFile({
        file_path: dataURL,
        file_type: fileType,
      })
      await this.startManualEdit()
    },
    startManualEdit() {
      this.currentView = 'manualEdit'
    },
    startImageCamera() {
      this.currentView = 'imageCamera'
    },
    startVideoPreview() {
      this.currentView = 'videoPreview'
      this.original_file_data = this.currentStep.file_path
      this.original_file_type = this.currentStep.file_type
    },
    setImageCapture(dataURL, fileType) {
      this.currentView = 'imageEditor'
      this.original_file_data = dataURL
    },
    startSelectFile() {
      this.$refs.manualStepDataEdit.selectFile()
    },
    selectedImage(dataURL, fileType) {
      this.currentView = 'imageEditor'
      this.original_file_data = dataURL
    },
    selectedVideo(dataURL, fileType) {
      this.currentView = 'videoViewer'
      this.original_file_data = dataURL
      this.original_file_type = fileType
    },
    deleteFile() {
      this.editItem({
        file_path: '',
        file_type: '',
      })
    },
  },
}
</script>

<style scoped>
#header-alert {
  color: #fff;
  font-weight: bold;
  font-size: small;
  text-align: center;
}

.update-header {
  background-color: #006400;
}

.header-img-container {
  width: 105px;
}

.header-img-container img {
  display: block;
  width: 100%;
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  background-color: #333;
}
</style>
