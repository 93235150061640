<template>
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div v-if="showManualCamera" class="modal-content">
        <ManualImageCamera
          ref="manualImageCamera"
          @hidden-camera="stopImageCamera"
          @capture-camera="setImageCapture"
        />
      </div>
      <div v-else class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ formData.title }}&nbsp;</h5>
        </div>

        <div class="modal-body">
          <form class="row">
            <div class="col-lg-6">
              <div
                class="img-draggable"
                @dragenter.prevent="dragEnter"
                @dragleave.prevent="dragLeave"
                @dragover.prevent
                @drop.prevent="dropFile"
                :class="{ 'img-draggable-enter': isDragEnter }"
              >
                <div v-if="formData.file_path">
                  <ImageViewer
                    ref="imageViewerElem"
                    :file="{
                      file_path: formData.file_path,
                      file_type: formData.file_type,
                    }"
                    :show_lightbox="false"
                  />
                </div>
                <div v-else class="ratio ratio-16x9">
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                  >
                    <i class="bi bi-image fs-1"></i>
                    <span class="text-center"
                      >対応ファイル種別：<br class="d-md-none" />{{
                        this.accept
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div v-if="formData.file_path">
                <span class="link-text link-danger" @click.prevent="deleteFile"
                  ><i class="bi bi-trash3 text-danger me-1"></i
                  >この画像を削除する</span
                >
              </div>
              <div class="row mt-3">
                <div class="col-md-6 mb-3">
                  <button
                    class="btn btn-secondary w-100 p-3 me-2"
                    @click.prevent="startImageCamera"
                  >
                    <i class="bi bi-camera me-1"></i>カメラで撮影する
                  </button>
                </div>
                <div class="col-md-6 mb-3">
                  <FileSelect
                    ref="fileSelect"
                    @selected-func="fileSelected"
                    :accept="accept"
                  >
                    <button
                      type="button"
                      class="btn btn-light w-100 p-3 me-2"
                      @click.prevent="fileSelect"
                    >
                      <i class="bi bi-folder2 me-1"></i>ファイルを選択
                    </button>
                  </FileSelect>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mb-3">
                <label for="title" class="form-label"
                  >タイトル<span class="ms-2 small">*必須項目です</span></label
                >
                <input
                  v-model="formData.title"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': formErrors?.title }"
                  id="title"
                />
                <div v-if="formErrors?.title" class="invalid-feedback">
                  {{ formErrors?.title }}
                </div>
              </div>
              <div class="mb-3">
                <label for="summary" class="form-label">説明文</label>
                <textarea
                  v-model="formData.summary"
                  class="form-control"
                  id="summary"
                  rows="3"
                ></textarea>
              </div>
              <div v-if="categories.length > 0" class="mb-3">
                <div>
                  <label for="category" class="form-label">カテゴリー</label>
                </div>
                <VueMultiselect
                  v-model="formData.categories"
                  :options="categories"
                  :multiple="true"
                  :searchable="false"
                  label="name"
                  track-by="id"
                  placeholder="選択してください"
                  selectLabel=""
                  selectedLabel="選択済み"
                  deselectLabel="選択解除"
                >
                </VueMultiselect>
              </div>
              <div v-if="statuses.length > 0" class="mb-3">
                <div>
                  <label for="status" class="form-label">公開種別</label>
                </div>
                <div class="mb-3">
                  <span v-for="(status, index) in statuses" :key="index">
                    <input
                      v-model="formData.status"
                      type="radio"
                      class="btn-check"
                      :id="index"
                      :value="status.value"
                      autocomplete="off"
                    />
                    <label
                      class="btn btn-outline-dark rounded-0 me-1 mb-1"
                      :for="index"
                      >{{ status.name }}</label
                    >
                  </span>
                </div>
              </div>
              <div
                v-if="formData.status == 'limited' && members.length > 0"
                class="mb-3"
              >
                <div>
                  <label for="viewers" class="form-label"
                    >閲覧許可ユーザー</label
                  >
                </div>
                <VueMultiselect
                  v-model="formData.viewers"
                  :options="members"
                  :multiple="true"
                  :searchable="true"
                  label="display_name"
                  track-by="id"
                  placeholder="選択してください"
                  selectLabel=""
                  selectedLabel="選択済み"
                  deselectLabel="選択解除"
                >
                </VueMultiselect>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <span v-if="Object.keys(formErrors).length > 0" class="text-danger"
            >入力内容を確認してください</span
          >
          <span v-if="isFormSubmitted" class="text-success">保存しました</span>
          <button
            class="btn btn-secondary"
            type="button"
            @click.prevent="formCancel"
            :disabled="isFormSubmitted"
          >
            キャンセル
          </button>
          <button
            class="btn btn-dark"
            type="button"
            @click.prevent="formSubmit"
            :disabled="isFormSubmitted"
          >
            保存する
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { getMimeType } from '@/utils/fileTypeUtils.js'
import { randomString } from '@/utils/stringUtils.js'
import VueMultiselect from 'vue-multiselect'
import FileSelect from '@/components/AppFileSelect.vue'
import ManualImageCamera from './ManualImageCamera.vue'
import ImageViewer from '@/components/AppImageViewer.vue'

export default {
  name: 'ManualForm',
  components: {
    VueMultiselect,
    FileSelect,
    ManualImageCamera,
    ImageViewer,
  },

  emits: ['hiddenModal', 'afterSubmit'],
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    members: {
      type: Array,
      default: () => [],
    },
    postOrPatch: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      modal: null,
      formData: {},
      formErrors: {},
      showManualCamera: false,
      isDragEnter: false,
      isFormSubmitted: false,
      accept: 'image/jpeg,image/png',
    }
  },
  computed: {
    modalId: function () {
      return 'modal-' + this.randomString()
    },
  },

  watch: {
    'formData.status': function (newVal) {
      this.statusChange(newVal)
    },
  },

  mounted() {
    this.modal = new Modal(document.getElementById(this.modalId))
    document
      .getElementById(this.modalId)
      .addEventListener('hidden.bs.modal', () => {
        this.formErrors = {}
        this.$emit('hiddenModal')
      })
  },
  beforeUnmount() {
    this.modal.hide()
  },

  methods: {
    getMimeType,
    randomString,
    showModal(formData) {
      this.formData = Object.assign({}, formData)
      this.isFormSubmitted = false
      this.modal.show()
    },
    dragEnter() {
      this.isDragEnter = true
    },
    dragLeave() {
      this.isDragEnter = false
    },
    dropFile(event) {
      this.$refs.fileSelect.onFileChange(event)
      this.isDragEnter = false
    },
    deleteFile() {
      this.formData.file_path = ''
      this.formData.file_type = ''
    },
    async formSubmit() {
      try {
        this.formErrors = {}
        if (this.formValidation() == false) return
        await this.postOrPatch(this.formData)
        this.isFormSubmitted = true
        window.setTimeout(async () => {
          await this.$emit('afterSubmit')
          await this.modal.hide()
        }, 1000)
      } catch (e) {
        if (e.response.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            if (key == 'data_limit') {
              this.modal.hide()
              this.$store.dispatch('alert/setErrorMessage', {
                message: e.response.data[key].join('\n'),
              })
            } else {
              this.setErrorMessage(key, e.response.data[key])
            }
          })
        }
      }
    },
    formValidation() {
      if (!this.formData.title) {
        this.setErrorMessage('title', 'タイトルを入力してください')
      }
      return Object.keys(this.formErrors).length == 0
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
    formCancel() {
      this.modal.hide()
    },
    fileSelect() {
      this.$refs.fileSelect.fileEvent()
    },
    fileSelected(file_path, file_type) {
      this.formData.file_path = file_path
      this.formData.file_type = file_type
    },
    startImageCamera() {
      this.showManualCamera = true
    },
    stopImageCamera() {
      this.showManualCamera = false
    },
    setImageCapture(file_path, file_type) {
      this.formData.file_path = file_path
      this.formData.file_type = file_type
      this.stopImageCamera()
    },
    statusChange(status) {
      if (status !== 'limited') {
        this.formData.viewers = []
      }
    },
  },
}
</script>

<style scoped>
.img-draggable {
  color: #aaa;
  width: 100%;
  border: 1px dashed #eee;
}

.img-draggable-enter {
  background-color: #eee;
}
</style>
