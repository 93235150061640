import api from '@/api'
import { fileUpload } from '@/utils/firebaseStorage.js'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  detail: {},
  list: [],
  current_index: null,
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  detail: (state) => state.detail,
  list: (state) => state.list,
  current_index: (state) => state.current_index,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
  setCurrentIndex: (state, index) => (state.current_index = index),
  clearCurrentIndex: (state) => (state.current_index = null),
  addItem(state) {
    state.list.push({
      description: '',
      file_path: null,
      file_type: null,
    })
  },
  editItem(state, newItem) {
    newItem = Object.assign(state.list[state.current_index], newItem)
    state.list.splice(state.current_index, 1, newItem)
  },
  deleteItem(state) {
    state.list.splice(state.current_index, 1)
  },
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearDetail')
      commit('clearList')
      commit('clearCurrentIndex')
      resolve()
    })
  },
  /**
   * リストを取得する
   *
   * @param {Number} マニュアルID
   * @return {void}
   */
  async fetchList({ commit }, manual_id) {
    const response = await api.get(
      'manage/manuals/' + manual_id + '/steps/list/'
    )
    commit('setList', response.data)
  },
  /**
   * ファイルをアップロードする
   *
   * @param {object}
   * @return {void}
   */
  async uploadFile({ state, commit, dispatch }, file_data) {
    let newItem = {}

    if (file_data['file_path'] && file_data['file_type']) {
      try {
        commit('loading/set', {}, { root: true })
        const snapshot = await fileUpload(
          file_data['file_path'],
          file_data['file_type']
        )
        commit('loading/clear', {}, { root: true })
        console.log('アップロードが完了しました')
        newItem = Object.assign(state.list[state.current_index], {
          position: state.current_index + 1,
          file_path: snapshot.metadata.fullPath,
          file_type: snapshot.metadata.contentType,
          file_name: snapshot.metadata.name,
          file_size: snapshot.metadata.size,
        })
      } catch (error) {
        console.log('アップロードに失敗しました')
      }
    }

    try {
      await dispatch('updatedFile', newItem)
      await commit('editItem', state.detail)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを作成または更新する
   * ファイルアップロード後の処理
   *
   * @param {object}
   * @return {void}
   */
  async updatedFile({ commit, rootState }, form_data) {
    // 必要なデータのみにする
    const required_keys = [
      'id',
      'position',
      'file_path',
      'file_type',
      'file_name',
      'file_size',
    ]
    const submit_data = Object.fromEntries(
      Object.entries(form_data).filter(([key, value]) =>
        required_keys.includes(key)
      )
    )

    try {
      let response = {}
      if ('id' in submit_data && submit_data['id']) {
        response = await api.patch(
          'manage/steps/' + form_data.id + '/file/',
          form_data
        )
      } else {
        const manual_id = rootState.manage_manual.detail.id
        response = await api.post(
          'manage/manuals/' + manual_id + '/steps/file/',
          form_data
        )
      }
      commit('setDetail', response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {void}
   */
  async postData({ commit, rootState }, form_data) {
    try {
      const manual_id = rootState.manage_manual.detail.id
      const response = await api.post(
        'manage/manuals/' + manual_id + '/steps/',
        form_data
      )
      commit('setDetail', response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {void}
   */
  async patchData({ commit }, form_data) {
    try {
      const response = await api.patch(
        'manage/steps/' + form_data.id + '/',
        form_data
      )
      commit('setDetail', response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを作成または更新する
   *
   * @param {object}
   * @return {void}
   */
  async postOrPatch({ dispatch }, form_data) {
    // 必要なデータのみにする
    const required_keys = ['id', 'position', 'description']
    // file_path が空の場合は送信データに含める（ファイル削除）
    if (form_data['file_path'] == '') {
      required_keys.push('file_path')
      required_keys.push('file_type')
    }
    const submit_data = Object.fromEntries(
      Object.entries(form_data).filter(([key, value]) =>
        required_keys.includes(key)
      )
    )

    try {
      if ('id' in submit_data && submit_data['id']) {
        await dispatch('patchData', submit_data)
      } else {
        await dispatch('postData', submit_data)
      }
    } catch (error) {
      throw error
    }
  },
  /**
   * データをまとめて作成または更新する
   * マニュアル編集終了時の処理
   *
   * @param {array}
   * @return {void}
   */
  async bulkPostOrPatch({ dispatch }, array_data) {
    await dispatch('trimMissingData', array_data)

    array_data.forEach(async (form_data, i) => {
      form_data.position = i + 1
      await dispatch('postOrPatch', form_data)
    })
  },
  /**
   * 配列に含まれないデータをまとめて削除する
   *
   * @param {object}
   * @return {void}
   */
  async trimMissingData({ dispatch, rootState }, array_data) {
    const diff_step_ids = getDiffIds(
      rootState.manage_manual.detail.steps,
      array_data
    )
    if (diff_step_ids.length > 0) {
      await dispatch('bulkDelete', diff_step_ids)
    }
  },
  /**
   * データを削除する
   *
   * @param {Number}
   * @return {void}
   */
  async deleteData({}, id) {
    await api.delete('manage/steps/' + id + '/')
  },
  /**
   * データをまとめて削除する
   *
   * @param {array}
   * @return {void}
   */
  async bulkDelete({ dispatch }, ids) {
    ids.forEach(async (id) => {
      await dispatch('deleteData', id)
    })
  },
  /**
   * データを並び替える
   */
  upItem({ commit, state }, index) {
    if (Number.isInteger(index) && index > 0) {
      let newIndex = index - 1
      let newSteps = [...state.list]
      newSteps.splice(newIndex, 2, newSteps[index], newSteps[newIndex])
      commit('setList', newSteps)
      return newIndex
    }
  },
  downItem({ commit, state }, index) {
    if (Number.isInteger(index) && index < state.list.length - 1) {
      let newIndex = index + 1
      let newSteps = [...state.list]
      newSteps.splice(index, 2, newSteps[newIndex], newSteps[index])
      commit('setList', newSteps)
      return newIndex
    }
  },
}

function getDiffIds(current_data, form_data) {
  if (!current_data || current_data.length == 0) {
    return []
  }

  const ids = form_data.map((obj) => {
    if (obj.hasOwnProperty('id')) {
      return obj.id
    }
  })
  const current_ids = current_data.map((obj) => {
    if (obj.hasOwnProperty('id')) {
      return obj.id
    }
  })
  return current_ids.filter((x) => !ids.includes(x))
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
