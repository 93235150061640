<template>
  <BaseNavbar />
</template>

<script>
import BaseNavbar from '@/components/navbar/BaseNavbar.vue'

export default {
  name: 'MemberNavbar',
  components: {
    BaseNavbar,
  },
}
</script>
