<template>
  <div v-if="is_loading" class="position-relative">
    <div
      class="position-fixed top-0 start-0 w-100 h-100 bg-dark opacity-50"
      style="z-index: 9999"
    ></div>
    <div class="position-fixed top-50 start-50 translate-middle">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AppLoading',

  computed: {
    ...mapGetters({
      loading: 'loading/loading',
    }),
    is_loading: {
      get() {
        return !!this.loading
      },
      set(val) {
        return val
      },
    },
  },

  watch: {
    $route(to, from) {
      if (to != from) {
        this.clearAll()
      }
    },
  },

  methods: {
    ...mapActions({
      clearAll: 'loading/clearAll',
    }),
  },
}
</script>
