import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  list: (state) => state.list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearList')
      resolve()
    })
  },
  /**
   * リストを取得する
   *
   * @return {void}
   */
  async fetchList({ state, commit }) {
    if (state.list.length > 0) {
      return
    }

    const response = await api.get('manage/categories/list/')
    commit('setList', response.data)
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {void}
   */
  async postData({}, form_data) {
    try {
      await api.post('manage/categories/', form_data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを更新する
   *
   * @param {object}
   * @return {void}
   */
  async patchData({}, form_data) {
    try {
      await await api.patch(
        'manage/categories/' + form_data.id + '/',
        form_data
      )
    } catch (error) {
      throw error
    }
  },
  /**
   * データを作成または更新する
   *
   * @param {object}
   * @return {void}
   */
  async postOrPatch({ dispatch }, form_data) {
    try {
      if ('id' in form_data && form_data['id']) {
        await dispatch('patchData', form_data)
      } else {
        await dispatch('postData', form_data)
      }
      await dispatch('clearAll')
      await dispatch('fetchList')
    } catch (error) {
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ dispatch }, id) {
    await api.delete('manage/categories/' + id + '/')
    dispatch('clearAll')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
